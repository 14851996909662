import getCardContext from "./cart/getCardContext";
import getContext, { ICartContext } from "./cart/getContext";
import setupCart from "./cart/setup";
import store from "./cart/store";
let cartPromise = setupCart();

export default {
  get() {
    return cartPromise;
  },
  persist() {
    return cartPromise.then(store.store);
  },
  clear() {
    return cartPromise.then(store.clear).then(() => {
      cartPromise = setupCart();
    });
  },
  getContext(): JQueryPromise<ICartContext> {
    return cartPromise.then(getContext);
  },
  getCardContext() {
    return cartPromise.then(getCardContext);
  },
  reset() {
    // clear all but the customer...
    // KMT-21882 - Aangepaste klantprofielen verjaardagsabonnent

    // ...and the chosen payment method
    // KMT-21983 Foutmelding 2e order

    this.get().then((cart) => {
      if (!cart) {
        return;
      }
      const customer = cart.get("address").get("customer");
      const payment = cart.get("payment");
      return this.clear().then(() => {
        this.get().then((newCart) => {
          if (!newCart) {
            return;
          }
          newCart.get("address").set("customer", customer);
          newCart.set("payment", payment);
        });
      });
    });
  },
};
