import Backbone from "backbone";
import $ from "jquery";
import "../../sass/boeketcadeau2014/main.scss";
import "./global/lazyLoad";
import "./global/menu";
import "./global/polyfill";
import "./global/router";
import "./global/timers";

import { init as homePageInit } from "./pages/home";
import { init as catalogPageInit } from "./pages/catalog";
import { init as profilePageInit } from "./pages/profile";
import "./pages/luxuryCard";
import "./pages/trackTrace";

if (process.env.NODE_ENV !== "development") {
  $(document).on("contextmenu", "img", () => {
    window.alert("Op deze afbeeldingen rust het auteursrecht. \nCopyright by BoeketCadeau.nl");
    return false;
  });
}

// lazy load images
$("img[data-src],[data-background-image-url],iframe[data-src]").lazyLoad();

$(document).ready(() => {
  homePageInit();
  catalogPageInit();
  profilePageInit();

  const $cartFormContainer = $(".cartFormContainer");
  if ($cartFormContainer.length) {
    import("./pages/cart").then((page) => page.init($cartFormContainer));
  }
  const $weblogItems = $(".weblogOverview,.weblogDetails");
  if ($weblogItems.length) {
    import("./pages/blog").then((page) => page.init($weblogItems));
  }
  const $thanksContainer = $(".thanksContainer");
  if ($thanksContainer.length) {
    import("./pages/payment").then((page) => page.init($thanksContainer));
  }
  const $ratingRadio = $(".ratingRadio");
  if ($ratingRadio.length) {
    import("./pages/guestbook").then((page) => page.init($ratingRadio));
  }
  const $bloemenAbcList = $("#bloemen-abc-list");
  if ($bloemenAbcList.length) {
    import("./pages/funPage").then((page) => page.init($bloemenAbcList));
  }

  // Googlebot does this twice? --- prevent superfluous error reporting...
  if (!Backbone.History.started) {
    Backbone.history.start();
  }

  $(window).scroll(() => {
    // top = Number();
    const toggleOnY = 100;
    if (document.body.scrollTop > toggleOnY || document.documentElement.scrollTop > toggleOnY) {
      $("#backToTop").stop().fadeIn();
    } else {
      $("#backToTop").stop().fadeOut();
    }
  });
  $("#backToTop").click(() => {
    $("html, body").animate({ scrollTop: 0 });
    return false;
  });
});

/**
 * Chromium 38 on Samsung Galaxy s6 radio change events arent fired
 */
const webkitInfo = /WebKit\/([0-9]+)/.exec(navigator.appVersion);
if (
  navigator &&
  navigator.userAgent &&
  navigator.appVersion &&
  navigator.userAgent.indexOf("Android") >= 0 && // is android
  navigator.userAgent.indexOf("SamsungBrowser") >= 0 && // is samsung native
  webkitInfo &&
  parseInt(webkitInfo[1], 10) === 537 // == s6 version
) {
  $(document)
    .on("click", 'input[type="radio"]', function click(this: any) {
      if ($(this).data("state") !== $(this).val()) {
        $(this).change();
      }
    })
    .on("change", 'input[type="radio"]', function change(this: any) {
      // ts-ignore
      $(this).data("state", $(this).val());
    });
}

window.addEventListener("load", () => {
  function updateOnlineStatus() {
    const $el = $(".alert-offline");
    if (window.navigator.onLine) {
      $el.remove();
      return;
    }
    if ($el.length > 0) {
      return;
    }
    $("body").prepend(
      $("<div/>")
        .addClass("alert")
        .addClass("alert-danger")
        .addClass("alert-offline")
        .html('<div class="alert__icon" />U bent offline, het is niet mogelijk een bestelling te plaatsen')
    );
  }
  window.addEventListener("online", updateOnlineStatus);
  window.addEventListener("offline", updateOnlineStatus);
});

// ReactDOM.render(<App />, document.getElementById('root'));
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
