import $ from "jquery";
import moment from "./moment/amsterdam";

function pad(str: string) {
  str = str.toString();
  return str.length < 2 ? `0${str}` : str;
}

const currentMoment = moment(new Date($("body").data("now") * 1000)).tz("Europe/Amsterdam");

$(".timer").each(function (this: any) {
  let secondsToTarget: number;

  const $this = $(this);
  const countDown = () => {
    secondsToTarget -= 1;
    if (secondsToTarget <= 0) {
      $this.html("<blink>0:00:00</blink>");
      // document.location.reload();
      return;
    }

    let seconds = secondsToTarget;
    const hours = Math.floor(secondsToTarget / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    $this.html(`${hours}:${pad(`${minutes}`)}:${pad(`${seconds}`)}`);
    return setTimeout(countDown, 1000);
  };

  const targetMoment = moment(new Date($this.data("target") * 1000)).tz("Europe/Amsterdam");
  secondsToTarget = targetMoment.diff(currentMoment, "seconds");
  if (secondsToTarget > 0) {
    setTimeout(countDown, 1000);
  }
});
