// @ts-ignore
import BackboneSchema from "backbone-json-schema";
import $ from "jquery";
import { JSONSchema4 } from "json-schema";
import globalBaseModels from "./baseModels";
import globalSchema from "./schema";

const factory = new BackboneSchema.Factory();

function lcfirst(target: string) {
  return target.charAt(0).toLowerCase() + target.slice(1);
}

function initSchema(schemaType: string) {
  return globalSchema[`get${schemaType}Promise` as "getCartPromise"]().then((schema?: JSONSchema4) => {
    if (!schema) {
      return;
    }
    schema.id = `/gateway/schema/boeketcadeau2014?localAction=${lcfirst(schemaType)}`;
    // This breaks stuff like the configured title on PaymentType schema
    // schema.title = schemaType;
    factory.register(schema, globalBaseModels[schemaType as "Customer"]);
  });
}

const factoryInitPromise = $.when(
  initSchema("PaymentType"),
  initSchema("DeliveryAddress"),
  initSchema("Customer"),
  initSchema("Cart"),
  initSchema("AddProductCollectionMemberRequest")
);

export default {
  create(schemaName: string) {
    return factoryInitPromise.then(() =>
      factory.create(`/gateway/schema/boeketcadeau2014?localAction=${lcfirst(schemaName)}`)
    );
  },
};
