import "./trackTrace.scss";
import $ from "jquery";
import hbs, { getTemplate } from "../global/renderEngine";

const $el = $("#order-tracktrace-container");
const tpl = hbs.compile(getTemplate("trackTrace"));

let socket = null;

function refreshStatus() {
  $el.find(".loader").show();
  return $.get(`/gateway/api/order/${$el.data("id")}/status`, {
    crc: $el.data("crc"),
  })
    .then((result) => {
      $el.find(".loader").hide();
      $el.find(".shipment-timeline").show();

      if (result.status === "error" || result.status === "unknown") {
        $el
          .find(".shipment-timeline")
          .addClass("shipment-error")
          .html("Het is op dit moment niet mogelijk de bezorgstatus op te vragen, probeer later nogmaals");
        return;
      }

      const isDelivered = /^delivered/.test(result.status);
      const inTransit = result.status !== "cancelled" && !isDelivered;

      const deliveryDotClass = ".shipment-progress-dot--delay1";

      const transitDotsClass =
        ".shipment-progress-dot--delay2,.shipment-progress-dot--delay3," +
        ".shipment-progress-dot--delay4,.shipment-progress-dot--delay5";

      const transitDotClass = ".shipment-progress-dot--delay5";

      const deliveredProgressDotsClass =
        ".shipment-progress-dot--delay6,.shipment-progress-dot--delay7," +
        ".shipment-progress-dot--delay8,.shipment-progress-dot--delay9";

      const deliveredDotClass = ".shipment-progress-icon.shipment-progress-dot--delay9";

      if (!isDelivered && !socket && "WebSocket" in window && $el.data("socketUri")) {
        socket = new WebSocket($el.data("socketUri"));
        socket.onmessage = function onmessage(e) {
          if ($el.data("crc") !== JSON.parse(e.data)) {
            return;
          }
          refreshStatus();
        };
      }

      if (inTransit || isDelivered) {
        $el.find(deliveryDotClass).addClass("shipment-progress-dot--active");

        $el.find(transitDotsClass).addClass("shipment-progress-dot--active");
        $el.find(transitDotClass).addClass("shipment-progress-dot--active").addClass("shipment-progress-icon--active");
      }

      if (result.status === "failed") {
        $el.find(deliveryDotClass).addClass("shipment-progress-dot--active");

        $el.find(transitDotsClass).addClass("shipment-progress-dot--active");

        $el.find(transitDotClass).addClass("shipment-progress-dot--failed").addClass("shipment-progress-icon--failed");
      }

      if (result.status === "cancelled") {
        $el
          .find(deliveryDotClass)
          .addClass("shipment-progress-dot--cancelled")
          .addClass("shipment-progress-icon--cancelled");
        if (socket) {
          socket.close();
        }
      }

      if (isDelivered) {
        $el.find(deliveredProgressDotsClass).addClass("shipment-progress-dot--active");
        $el.find(deliveredDotClass).addClass("shipment-progress-icon--active");
        if (socket) {
          socket.close();
        }
      }

      $el.find(".shipment-message").html(tpl(result));

      if (
        result.url.indexOf(`${window.document.location.host}/${window.document.location.pathname.split("/")[1]}`) === -1
      ) {
        $el
          .find(".shipment-address-box")
          .after(
            `<a href="${result.url}" rel="noreferrer noopener" target="_blank">Bekijk de track en trace pagina van de vervoerder</a></div><br/><br/><br/>`
          );
      }
    })
    .fail(() => {
      $el
        .find(".loader")
        .html("Het is op dit moment niet mogelijk de bezorgstatus op te vragen, probeer later nogmaals");
    });
}
if ($el.length > 0) {
  refreshStatus();
}
