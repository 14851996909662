import $ from "jquery";
import menuDropdownOverlay from "./menu/dropdownOverlay";
import menuLogin from "./menu/login";
import menuCart from "./menu/cart";
import * as utils from "./utils";

$(document).ready(() => {
  menuDropdownOverlay.init();
  menuCart.init();
  menuLogin.init();

  // align login form to the menu button
  const $closeButton = $(".closeButton", ".loginBar");
  const $menuButtonLi = $('[href="#loginBar"]').parent();
  if ($menuButtonLi.length) {
    $closeButton.css({
      "margin-right": `${
        $menuButtonLi.offsetParent().width() - $menuButtonLi.width() - $menuButtonLi.position().left
      }px`,
    });
  }

  if (utils.isMobileVisit()) {
    // always close the menu initially for mobile visitors
    $(".navigation").removeClass("open");

    // more.. is never selected
    $(".showMoreContainer").removeClass("selected");
  }

  $("html")
    .addClass("ready")
    .on("click", ".cartNavigation .back", () => {
      window.history.back();
      return false;
    });

  // hamburger
  $(".productCategories > li > a:not(.back)").click(() => {
    $("body").toggleClass("navigationOpen");
    return false;
  });

  // add menu logic and chevrons
  const $lisWithSubmenu = $("#productCategoryNavigation > li > ol, .footerMenu li > ol").parent();
  $lisWithSubmenu
    .children("span")
    .click(function click() {
      if (utils.isMobileVisit()) {
        $(this).parent().toggleClass("open").siblings().removeClass("open");
      }
      return false;
    })
    .children("span")
    .append('<span class="chevron fa fa-chevron-down"></span>');

  // read more / read less behaviour
  $(".footerMenu > ol > li > ol").each(function each() {
    const $this = $(this);
    let $toBeHiddenLis = $this.find("li:gt(4)");
    if ($toBeHiddenLis.length > 0) {
      $this.append('<li class="readLess"><a href="#">Minder</a></li>');
      $toBeHiddenLis = $this.find("li:gt(4)");
      $toBeHiddenLis.hide();
      $this.append('<li class="readMore"><a href="#">Lees meer</a></li>');
    }
  });

  $(".footerMenu")
    .on("click", ".readMore", function click() {
      const $this = $(this);
      $this.parent().parent().find("li").show();
      $this.hide();
      return false;
    })
    .on("click", ".readLess", function click() {
      const $parentOl = $(this).parent().parent();
      $parentOl.find("li:gt(4)").hide();
      $parentOl.find(".readMore").show();
      return false;
    });
});
