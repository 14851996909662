import BaseView from "./Base";
import $ from "jquery";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/tooltip";
import schema from "../global/schema";
import formGenerator from "../global/form/generator";
import formValidator from "../global/form/validator";
import globalCart from "../global/cart";

export default BaseView.extend({
  schemaId: "deliveryAddress",

  events: {
    "input :input:not(#address-recipient-zipcode)": "updateModel",
    "change :input:not(#address-recipient-zipcode)": "updateModel",
    "change #address-recipient-zipcode": "updateModel",
    "keyup .zipcode input": "resetAddressError",
  },

  initialize() {
    const self = this;
    this.model.on("change:zipcode", () => {
      formValidator.getEnvironment().then((env) => {
        if (self.model.get("countryCode") !== "NL") {
          return;
        }
        if (!env.fieldValidate.checkAddressNoWadden(self.model.toJSON())) {
          // eslint-disable-next-line no-alert
          window.alert("Wij leveren geen bloemen of cadeaus naar de waddeneilanden.");
          return;
        }
        self.updateAddressByZipcode();
      });
    });
    this.model.on("change:countryCode", () => {
      self.lastZipcodeLookupResults = null;
      self.render();
    });
    this.model.on("change:addressType", this.render.bind(this));
    this.model.on("pro6pp_change:street", this.render.bind(this));
  },

  render() {
    const self = this;
    const path = this.path || [];
    $.when(globalCart.getContext(), schema.getDeliveryAddressPromise())
      .then((cartContext, deliveryAddressSchema) => {
        if (cartContext.isMourning) {
          // http://plunger.kingsquare.nl:8080/browse/KMT-98970
          deliveryAddressSchema.properties.addressType.enum = [""].concat(
            deliveryAddressSchema.properties.addressType.enum
              .filter((t) => {
                if (["", "company", "hotel"].indexOf(t) !== -1) {
                  return false;
                }
                return true;
              })
              .sort((a, b) => {
                /*
            Uitvaartcentrum / Crematorium
            Kerk
            Woning
            Verzorgingshuis
            Ziekenhuis
            Anders
            */
                if (a === "funeral center") {
                  return -1;
                }
                if (b === "funeral center") {
                  return 1;
                }

                if (a === "church") {
                  return -1;
                }
                if (b === "church") {
                  return 1;
                }

                if (a === "residence") {
                  return -1;
                }
                if (b === "residence") {
                  return 1;
                }

                if (a === "nursing home") {
                  return -1;
                }
                if (b === "nursing home") {
                  return 1;
                }
                return 0;
              })
          );

          deliveryAddressSchema.properties.lastName.title = "Naam overledene";
          switch (self.model.get("addressType")) {
            case "funeral center":
              deliveryAddressSchema.properties.addition1.title = "Naam uitvaartcentrum/crematorium";
              break;

            case "church":
              deliveryAddressSchema.properties.addition1.title = "Naam kerk";
              break;

            case "hospital":
              deliveryAddressSchema.properties.addition1.title = "Naam ziekenhuis";
              deliveryAddressSchema.properties.addition2.title = "Afdeling";
              deliveryAddressSchema.properties.addition3.title = "Kamernummer";
              break;

            case "nursing home":
              deliveryAddressSchema.properties.addition1.title = "Naam verzorgingshuis";
              deliveryAddressSchema.properties.addition2.title = "Kamernummer";
              break;

            default:
          }
        }
        return $.when(
          formGenerator.render(deliveryAddressSchema, path, self.model.toJSON()),
          deliveryAddressSchema,
          cartContext
        );
      })
      .then((formHtml, deliveryAddressSchema, cartContext) => {
        self.$el.html(formHtml);
        // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        // http://plunger.kingsquare.nl:8080/browse/KMT-99950
        self.$el.find("input,select").attr("autocomplete", "nope");
        self.$el.find(".phone label").tooltip();
        self.$el
          .find("#address-recipient-addressType")
          .find("option")
          .first()
          .text("Maak uw keuze")
          .css("color", "silver");

        // self.$el
        //   .find("#address-recipient-zipcode")
        //   .attr("type", "number")
        //   .on("keyup input", function(e) {
        //     // eslint-disable-next-line no-console
        //     console.log(e.target.value, String(e.target.value).length);
        //     if (String(e.target.value).length >= 4) {
        //       this.type = "text";
        //     } else {
        //       this.type = "number";
        //     }
        //   });

        const labels = [];

        const isMourning =
          cartContext.isMourning ||
          self.model.get("addressType") === "funeral center" ||
          self.model.get("addressType") === "church";

        if (self.model.get("addressType") === "church") {
          self.$el.find(".dateTimeService label").text("Datum en tijdstip van kerkdienst");
        }

        self.$(`.addition1, .addition2, .addition3${isMourning ? "" : ", .dateTimeService"}`).remove();

        switch (self.model.get("addressType")) {
          case "residence":
            break;
          case "company":
            labels.push("Bedrijfsnaam", "Evt. afdeling");
            break;
          case "hospital":
            labels.push("Ziekenhuis", "Afdeling", " Kamernummer");
            break;
          case "nursing home":
            labels.push("Naam verzorgingshuis", "Evt. kamernummer");
            break;
          case "funeral center":
            labels.push("Naam uitvaartcentrum");
            break;
          case "church":
            labels.push("Naam kerk");
            break;
          case "hotel":
            labels.push("Naam hotel", "Evt. kamernummer");
            break;
          case "other":
            labels.push("Opmerking 1", "Opmerking 2");
            break;
          default:
        }

        const newHtmlPromises = [];

        const fixAddition = function fixAddition(i) {
          const additionId = i + 1;
          if (labels[i]) {
            const fieldPath = path.slice();
            fieldPath.push(`addition${additionId}`);
            newHtmlPromises.push(
              formGenerator
                .renderChunk(
                  fieldPath,
                  deliveryAddressSchema.properties[`addition${additionId}`],
                  self.model.get(`addition${additionId}`)
                )
                .then((chunk) => chunk.replace(`Opmerking${additionId}`, labels[i]))
            );
          }
        };

        for (let i = 0; i < 3; i += 1) {
          fixAddition(i);
        }
        $.when(...newHtmlPromises).then(function t() {
          // eslint-disable-next-line prefer-rest-params
          self.$(".addressType").after($.makeArray(arguments).join(""));
        });

        // https://trello.com/c/sjVry0Wn/99-aanpassing-bij-bestellen-rouwboeket-in-aanhef-ontvanger
        if (cartContext.isMourning) {
          self.$('#address-recipient-salutation option[value="mr"]').html("Wijlen de heer");
          self.$('#address-recipient-salutation option[value="mrs"]').html("Wijlen mevrouw");
          self.$('#address-recipient-salutation option[value="fam"]').remove();
        }

        self.patchStreetAndCityInputs();
        const $dom = self.$(
          ".addition1, .addition2, .dateTimeService, .salutation, .initials, .middleName, .lastName, .zipcode," +
            ".streetNumber, .phone",
          ".recipient"
        );
        $dom[self.model.get("addressType") ? "show" : "hide"]("slow");

        self.trigger("render");
      });
    return this;
  },
});
