import isString from "lodash/isString";
import $ from "jquery";

export default {
  assignValueByInput: function (model, $input, modelPath) {
    let path = $input
      .prop("name")
      .replace(/^[[\]]+|[[\]]+$/g, "")
      .split(/[[\]]+/g);
    if (path[0] === "root") {
      path.shift();
    }
    if (modelPath && modelPath.length) {
      path = path.slice(modelPath.length);
    }
    let currentNibble = model;

    //typecasting / coercion is done via datatype data attributes, if available
    let value = $input.is('[type="checkbox"]') ? $input.is(":checked") : $input.val();

    //strip whitespace and stuff
    if (isString(value)) {
      value = value.trim();
    }

    const parentWithDataTypeHint = $input.parents("[data-datatype]");
    if (parentWithDataTypeHint.length) {
      switch (parentWithDataTypeHint.data("datatype")) {
        case "number":
          if (isString(value)) {
            value = parseFloat(value.replace(/[^\d.-]/g, ""));
          }
          break;
        default: // @todo implement more? (bool, string and number are covered)
      }
    }

    $.each(path, (key, nibble) => {
      // https://sentry.io/organizations/kingsquare/issues/1490441983/?project=86450
      if (!currentNibble) {
        return;
      }
      if (path.length - 1 === key) {
        if (currentNibble.set) {
          currentNibble.set(nibble, value);
        } else {
          //support for e.g. left- and right ribbons
          currentNibble[nibble] = value;
        }
        //model.trigger('change');
        return;
      }
      // at gives support for '0' in collections, e.g. item
      currentNibble = currentNibble[currentNibble.at ? "at" : "get"](nibble);
    });
  },
};
