import { JSONSchema4 } from "json-schema";
import * as utils from "./utils";

export default {
  getDeliveryAddressPromise(): JQueryPromise<JSONSchema4> {
    return utils.getCachedXhrPromise("get", "/gateway/schema/boeketcadeau2014", {
      localAction: "deliveryAddress",
    });
  },

  getCustomerPromise(): JQueryPromise<JSONSchema4> {
    return utils.getCachedXhrPromise("get", "/gateway/schema/boeketcadeau2014", {
      localAction: "customer",
    });
  },

  getPaymentTypePromise(): JQueryPromise<JSONSchema4> {
    return utils.getCachedXhrPromise("get", "/gateway/schema/boeketcadeau2014", {
      localAction: "paymentType",
    });
  },

  getCartPromise(): JQueryPromise<JSONSchema4> {
    return utils.getCachedXhrPromise("get", "/gateway/schema/boeketcadeau2014", {
      localAction: "cart",
    });
  },

  getAddProductCollectionMemberRequestPromise(): JQueryPromise<JSONSchema4> {
    return utils.getCachedXhrPromise("get", "/gateway/schema/boeketcadeau2014", {
      localAction: "addProductCollectionMemberRequest",
    });
  },
};
