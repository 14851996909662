import BaseView from "./Base";
import $ from "jquery";
import schema from "../global/schema";
import formGenerator from "../global/form/generator";
import Handlebars, { getTemplate } from "../global/renderEngine";

const invoiceEmailAddressToggleTemplateSource = getTemplate("customer/invoiceEmailAddressToggle");

const invoiceEmailAddressToggleTemplate = Handlebars.compile(invoiceEmailAddressToggleTemplateSource);

const getCompanyDetailsHtml = function (customer, path) {
  return schema.getCustomerPromise().then((customerSchema) => {
    if (!(customer && customer.get("type") === "company")) {
      return "";
    }
    const companyDetails = customer.get("companyDetails");
    const companyDetailsJSON = companyDetails.toJSON();
    return formGenerator
      .renderChunk(path, customerSchema.properties.companyDetails, companyDetailsJSON)
      .then((html) =>
        html.replace(
          '<div class="invoiceEmailAddress"',
          `${invoiceEmailAddressToggleTemplate(companyDetailsJSON)}<div class="invoiceEmailAddress"`
        )
      );
  });
};

export default BaseView.extend({
  schemaId: "customer",
  events: {
    "keyup #address-customer-emailAddress": "toggleCustomerDetails",
    "input :input:not(#address-customer-zipcode):not(#zipcode):not(#address-customer-emailAddress):not(#emailAddress)":
      "updateModel",
    "change :input:not(#address-customer-zipcode):not(#zipcode):not(#address-customer-emailAddress):not(#emailAddress)":
      "updateModel",
    "change #address-customer-zipcode": "updateModel",
    "change #address-customer-emailAddress": "updateModel",
    "change .toggleInvoiceEmailAddress input": "toggleInvoiceEmailAddress",
    "keyup .zipcode input": "resetAddressError",
    // register form
    "change #zipcode": "updateModel",
    "change #emailAddress": "updateModel",
  },

  initialize: function () {
    this.model.on("change:zipcode", this.updateAddressByZipcode.bind(this));
    this.model.on("change:type change:countryCode change:emailAddress", this.render.bind(this));
    this.model.on("pro6pp_change:street", this.render.bind(this));
    this.model.get("preferredPaymentMethod").on("change:type", this.render.bind(this));
  },

  // Defer render to fix (ms edge) race condition
  render: function () {
    setTimeout(this.deferredRender.bind(this), 1);
    return this;
  },

  deferredRender: function () {
    const self = this;
    const path = this.path || [];

    schema
      .getCustomerPromise()
      .then((customerSchema) => {
        const companyDetailsPath = path.slice();
        companyDetailsPath.push("companyDetails");

        return $.when(
          formGenerator.render(customerSchema, path, self.model.toJSON() || {}),
          getCompanyDetailsHtml(self.model, companyDetailsPath)
        );
      })
      .then((html, companyDetailsHtml) => {
        self.$el.html(html);
        // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        // http://plunger.kingsquare.nl:8080/browse/KMT-99950
        self.$el.find("input,select").attr("autocomplete", "nope");
        const currentCustomerType = self.model.get("type");
        const $preferredPaymentMethodType = $("#preferredPaymentMethod-type");
        self.$(".preferredPaymentMethod").toggle(currentCustomerType !== undefined);
        switch (currentCustomerType) {
          case "person":
            //Op rekening
            $preferredPaymentMethodType.find("option[value=943243]").remove();
            break;

          case "company":
            //After Pay
            $preferredPaymentMethodType.find("option[value=1]").remove();
            break;

          default:
            self.$(">div:not(.type,.legend)").hide();
            self.trigger("render");
            return;
        }

        const paymentMethod = self.model.get("preferredPaymentMethod");
        if (paymentMethod.get("type") !== 1) {
          //afterPayment
          self.$(".afterpayment").remove();
        }

        self.$(".companyDetails").html(companyDetailsHtml);
        if (!self.model.get("companyDetails").get("invoiceEmailAddress")) {
          self.$(".invoiceEmailAddress").remove();
        }

        const $preferredPaymentMethod = self.$el.find(".preferredPaymentMethod");
        $preferredPaymentMethod.toggle(
          self.$el.find('[name="root[companyDetails][invoicePeriod]"]:checked').val() !== "perMonth"
        );
        self.$el.on("change", '[name="root[companyDetails][invoicePeriod]"]', (event) => {
          $preferredPaymentMethod.toggle($(event.currentTarget).val() !== "perMonth");
          $(event.currentTarget).parent().parent().find(" > div").removeClass("checked");
          $(event.currentTarget).parent().addClass("checked");
        });

        self.patchStreetAndCityInputs();
        self.toggleCustomerDetails();

        self.$el.find(".radiogroup > div").removeClass("checked").prepend($("<div/>").addClass("selectedIcon"));

        self.$el.find("input:checked").parent().addClass("checked");

        self.trigger("render");
      });
    return this;
  },

  toggleInvoiceEmailAddress: function () {
    const companyDetails = this.model.get("companyDetails");
    //set empty or non-empty
    companyDetails.set("invoiceEmailAddress", this.$(".toggleInvoiceEmailAddress :checked").val() === "1" ? " " : "");
    //render again
    return this.render();
  },

  toggleCustomerDetails: function (e) {
    const emailAddress = e ? $(e.target).val() : this.model.get("emailAddress");
    this.$(".salutation, .initials, .middleName, .lastName, .zipcode, .anonymous, .streetNumber, .phone", ".recipient")[
      emailAddress ? "show" : "hide"
    ]();
    return false;
  },
});
