import $ from "jquery";
/**
 * The new iOS 8 / Safari 7 breaks Webkit when using nth-child selector in both JavaScript and/or CSS.
 *
 * This is an ugly work-a-round:
 *
 * - iPad and iOS use JavaScript to emulate nth-selectors WITHOUT ACTUALLY USING ONE. (RENDERING FAILS!)
 * - IE <= 8 use JavaScript to emulate nth-selectors.
 *
 * - Other devices and platforms load an extra stylesheet with the requested stuff
 */
const { userAgent } = window.navigator;
if (
  (userAgent.match(/iP(ad|hone)/i) && userAgent.match(/Version\/8/i)) ||
  (userAgent.match(/MSIE \d+/i) && /MSIE (\d+)/.exec(userAgent)[1] <= 8)
) {
  const applyOmega = function ($lis, omega) {
    $lis.each(function (key) {
      switch (key % omega) {
        case 0:
          $(this).css("clear", "left");
          break;

        case omega - 1:
          $(this).css("margin-right", "0");
          break;

        default:
      }
    });
  };

  $(document).ready(() => {
    applyOmega($(".omega3 li"), 3);
    applyOmega($(".omega4 li"), 4);
  });
}
