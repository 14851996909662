import $ from "jquery";
import Backbone from "backbone";
import MenuCartView from "../../view/MenuCart";
import globalCart from "../cart";
const HotModuleReloadableView = Backbone.View.extend({
  initialize(options) {
    const self = this;

    self.view = options.view;
    self.viewSrc = options.viewSrc;

    if (module.hot) {
      module.hot.accept("../../view/MenuCart", () => {
        // re-require your child view, this will give you the new hot-reloaded child view
        // eslint-disable-next-line import/no-unresolved,global-require
        self.view = MenuCartView;
        self.renderChild();
      });
    }
  },

  render() {
    this.renderChild();
    return this;
  },

  renderChild(ChildView) {
    const self = this;

    if (self.child) {
      self.child.stopListening();
      self.child = null;
    }

    self.child = new (ChildView || self.view)({
      el: self.el,
      model: self.model,
    });
    self.child.render();
  },
});

export default {
  init() {
    // do not display on the thanks page
    if ($(".thanksContainer").length === 1) {
      return;
    }
    globalCart.get().then((cart) => {
      const menuCart = new HotModuleReloadableView({
        view: MenuCartView,
        viewSrc: "../../view/MenuCart",
        el: $(".menuCart")[0],
        model: cart,
      });
      menuCart.render();
      return menuCart;
    });
  },
};
