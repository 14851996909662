import $ from "jquery";
import AddressBookView from "../view/AddressBook";
import DeliveryAddressFormView from "../view/DeliveryAddressForm";
import CustomerFormView from "../view/CustomerForm";
import HistoryView from "../view/profile/History";
import ChangePasswordView from "../view/profile/ChangePassword";
import modelFactory from "../global/modelFactory";
import globalCart from "../global/cart";

let currentView, $profileContentContainer, customer;

function render(view) {
  if (currentView) {
    //make sure current event listeners are unbound
    currentView.undelegateEvents();
  }
  currentView = view;
  view.render();
}

export function init() {
  $profileContentContainer = $(".profileContainer .contentContainer");
  if ($profileContentContainer.length !== 1) {
    return;
  }
  const data = $profileContentContainer.find(".data").val();
  if (!data) {
    return;
  }

  // KMT-72350
  if (typeof ga !== "undefined") {
    window.ga("set", "dimension1", "ingelogde gebruiker");
  }

  customer = JSON.parse(data);
  globalCart.get().then((cart) => {
    cart.get("address").get("customer").set(customer);
    if (customer.preferredPaymentMethod) {
      $.each(customer.preferredPaymentMethod, (paymentProperty, paymentPropertyValue) => {
        cart.get("payment").set(paymentProperty, paymentPropertyValue);
      });
    }
  });
}

export function routeAddressBook() {
  if ($profileContentContainer) {
    render(new AddressBookView({ el: $profileContentContainer[0] }));
  }
}

export function routeAddressBookEdit(id) {
  $.when(
    $.get("/gateway/addressBook", {
      localAction: "details",
      id: id,
    }),
    modelFactory.create("DeliveryAddress")
  ).then((data, DeliveryAddress) => {
    render(
      new DeliveryAddressFormView({
        model: new DeliveryAddress(data[0].Inhoud),
        el: $profileContentContainer[0],
      })
    );
  });
}
export function routeAddressBookAdd() {
  modelFactory.create("DeliveryAddress").then((DeliveryAddress) => {
    render(
      new DeliveryAddressFormView({
        model: new DeliveryAddress(),
        el: $profileContentContainer[0],
      })
    );
  });
}

export function routeHistory() {
  render(
    new HistoryView({
      el: $profileContentContainer[0],
    })
  );
}

export function routePassword() {
  render(
    new ChangePasswordView({
      el: $profileContentContainer[0],
    })
  );
}

export function routeProfile() {
  if ($profileContentContainer && customer) {
    modelFactory.create("Customer").then((Customer) => {
      render(
        new CustomerFormView({
          model: new Customer(customer),
          el: $profileContentContainer[0],
        })
      );
    });
  }
}

export function updateMenu(routeName) {
  if (routeName === "notFound") {
    routeName = "profile";
  }
  $(".profileContainer nav li").each(function () {
    const $li = $(this);

    // Required routeName can either be "routeX" or "routeXEdit"
    // i.e. "addressBook" or "addressBookEdit"

    $li.toggleClass("selected", routeName.replace(/Edit$/, "") === $li.children("a").data("id"));
  });
}
