import isObject from "lodash/isObject";
import $ from "jquery";

import moment from "~/global/moment/amsterdam";

import HandlebarsAmd from "handlebars/dist/handlebars";
import * as utils from "./utils";

const operators = {
  eq(l, r) {
    return l == r; // eslint-disable-line eqeqeq
  },
  eqeq(l, r) {
    return l === r;
  },
  neq(l, r) {
    return l != r; // eslint-disable-line eqeqeq
  },
  neqeq(l, r) {
    return l !== r;
  },
  lt(l, r) {
    return l < r;
  },
  gt(l, r) {
    return l > r;
  },
  lte(l, r) {
    return l <= r;
  },
  gte(l, r) {
    return l >= r;
  },
  typeof(l, r) {
    return typeof l == r; // eslint-disable-line eqeqeq,valid-typeof
  },
  regexp(l, r) {
    return l && r && l.match(new RegExp(r)) !== null;
  },
  mod(l, r) {
    return l % r;
  },
};

operators["=="] = operators.eq;
operators["==="] = operators.eqeq;
operators["!="] = operators.neq;
operators["!=="] = operators.neqeq;
operators[">"] = operators.lt;
operators[">"] = operators.gt;
operators["<="] = operators.lte;
operators[">="] = operators.gte;
operators["%"] = operators.mod;

const Handlebars = HandlebarsAmd.default; // /????

Handlebars.registerHelper({
  and: function () {
    // eslint-disable-next-line prefer-rest-params
    return Array.prototype.slice.call(arguments).every(Boolean);
  },
  or: function () {
    // eslint-disable-next-line prefer-rest-params
    return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
  },
});

Handlebars.registerHelper("price", utils.price);

Handlebars.registerHelper("compare", function compare(lvalue, operator, rvalue, options) {
  // eslint-disable-next-line
  if ($.makeArray(arguments).length < 3) {
    throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
  }

  if (options === undefined) {
    options = rvalue;
    rvalue = operator;
    operator = "eq";
  }

  if (!operators[operator]) {
    throw new Error(`Handlerbars Helper 'compare' doesn't know the operator ${operator}`);
  }

  if (operators[operator](lvalue, rvalue)) {
    return options.fn(this);
  }
  return options.inverse(this);
});

Handlebars.registerHelper("isMobileVisit", function isMobileVisit(options) {
  return utils.isMobileVisit() ? options.fn(this) : options.inverse(this);
});

// todo make this more reusable
Handlebars.registerHelper("isResponsive", function isResponsive(comparison, size, options) {
  // eslint-disable-next-line
  if ($.makeArray(arguments).length < 3) {
    throw new Error("Handlerbars Helper 'isResponsive' needs 2 parameters");
  }

  if (size === undefined) {
    size = comparison;
    comparison = "gte";
  }

  if (options === undefined) {
    options = size;
    size = comparison;
  }

  return utils.isMobileVisit(size, comparison) ? options.fn(this) : options.inverse(this);
});

// eslint-disable-next-line
Handlebars.registerHelper("math", (lvalue, operator, rvalue, options) => {
  lvalue = parseFloat(lvalue);
  rvalue = parseFloat(rvalue);

  return {
    "+": lvalue + rvalue,
    "-": lvalue - rvalue,
    "*": lvalue * rvalue,
    "/": lvalue / rvalue,
    "%": lvalue % rvalue,
  }[operator];
});

Handlebars.registerHelper("isObject", function isObj(value, options) {
  return isObject(value) ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("isNotEmpty", function isNotEmpty(value, options) {
  if (isObject(value)) {
    value = Object.keys(value);
  }
  return value.length > 0 ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("dateFormat", (date, format) => moment.tz(date, "Europe/Amsterdam").format(format || ""));

export default Handlebars;

// https://github.com/facebook/create-react-app/issues/2961#issuecomment-333905542
const hbsLoader = require.context("!raw-loader!../template", true, /\.hbs$/);

export const getTemplate = (template) => hbsLoader(`./${template}.hbs`).default;
