import $ from "jquery";

/**
 * The star is either full, half or empty
 *
 * @param score
 * @returns {string}
 */
function getStarClassName(score) {
  const classname = `fa star-yellow`;
  if (score >= 1) {
    return `${classname} fa-star`;
  }
  if (score === 0.5) {
    return `${classname} fa-star-half-o`;
  }
  return `${classname} fa-star-o`;
}

/**
 * @param $target JQuery<HTMLElement>
 * @param score int
 */
export function drawStars($target, score) {
  if ($target.find(".stars").length) {
    return;
  }

  const $stars = $('<span class="stars">');

  for (let i = 0; i < 5; i += 1) {
    const $star = $("<span>");
    $star.addClass(getStarClassName(score - i));
    $stars.append($star);
  }
  $stars.prependTo($target);
}
