import moment from "~/global/moment/amsterdam";

let cartStorage;

// SessionStorage polyfill as found https://gist.github.com/remy/350433
// Mapped to cartStorage to overcome chrome private mode errors
function testStorage(storage) {
  storage.setItem("storage", "");
  if (storage.getItem("storage") !== "") {
    throw new Error("Storage test failed");
  }
  storage.removeItem("storage");
}

try {
  // Support for Mobile Safari in private mode --- it does not allow localStorage / sessionStorage
  testStorage(window.sessionStorage);
  cartStorage = window.sessionStorage;
} catch (e) {
  // ignore
}

if (typeof cartStorage === "undefined") {
  const Storage = function Storage() {
    function setData(data) {
      data = JSON.stringify(data);
      window.name = data;
    }

    function clearData() {
      window.name = "";
    }

    function getData() {
      let data;
      try {
        data = JSON.parse(window.name);
      } catch (e) {
        // ignore
      }
      return data && typeof data === "object" ? data : {};
    }

    // initialise if there's already data
    let data = getData();

    return {
      length: 0,
      clear() {
        data = {};
        this.length = 0;
        clearData();
      },
      getItem(key) {
        return data[key] === undefined ? null : data[key];
      },
      key(i) {
        // not perfect, but works
        let ctr = 0;
        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const k in data) {
          if (ctr === i) {
            return k;
          }
          ctr += 1;
        }
        return null;
      },
      removeItem(key) {
        delete data[key];
        this.length -= 1;
        setData(data);
      },
      setItem(key, value) {
        data[key] = `${value}`; // forces the value to a string
        this.length += 1;
        setData(data);
      },
    };
  };

  try {
    cartStorage = new Storage();
    testStorage(cartStorage);
  } catch (e) {
    // eslint-disable-next-line no-alert
    window.alert(
      "Uw browser kan vereiste informatie van www.boeketcadeau.nl niet opslaan.\n\n" +
        "Schakel privé-modus uit voor een juiste werking van de website."
    );
  }
}

function getInitialData() {
  return JSON.stringify({
    createDateTime: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
  });
}

export default {
  getData() {
    return cartStorage.getItem("cart") || getInitialData();
  },

  store(cart) {
    cartStorage.setItem("cart", JSON.stringify(cart.toJSON()));
  },

  clear(cart) {
    // make sure the menu-bar is properly updated
    cart.get("items").reset();

    // break down and "reset" the entire cart
    cartStorage.setItem("cart", JSON.stringify(getInitialData()));
  },
};
