import hasIn from "lodash/hasIn";
import map from "lodash/map";
/**
 * @see \BoeketCadeau\Model\SchemaValidator for PHP intepretation of these checks
 */
import $ from "jquery";
import jjv from "jjv";
import schemas from "../schema";
import scroll from "./scroll";

const env = jjv();
const subSchemaPromises = [];

// subschema's!
$.each(
  ["cart", "customer", "paymentType", "deliveryAddress", "addProductCollectionMemberRequest"],
  (key, subSchemaTitle) => {
    const promiseKey = `get${subSchemaTitle.charAt(0).toUpperCase() + subSchemaTitle.slice(1)}Promise`;
    subSchemaPromises.push(
      schemas[promiseKey]().then((subSchema) => {
        delete subSchema.id; // conflicts with other use of schema
        env.addSchema(`/gateway/schema/boeketcadeau2014?localAction=${subSchemaTitle}`, subSchema);
        env.addSchema(subSchemaTitle, subSchema);
      })
    );
  }
);

/**
 * checks given postalcode is not a dutch "wadden"-island code
 */
env.addCheck("checkAddressNoWadden", (address) => {
  if (!address || !address.countryCode || address.countryCode !== "NL" || !address.zipcode) {
    return true;
  }

  const zipcodeDigits = parseInt(address.zipcode.replace(/\D+/g, ""), 10);
  return !(
    (zipcodeDigits >= 1790 && zipcodeDigits <= 1799) ||
    (zipcodeDigits >= 8880 && zipcodeDigits <= 8899) ||
    (zipcodeDigits >= 9160 && zipcodeDigits <= 9169)
  );
});

/**
 * check for a valid zipcode if possible
 */
env.addCheck("checkAddressValidZipcode", (address, whitney, schema, path, context) => {
  // cart (!)
  if (typeof context.validCustomerZipcode !== "undefined") {
    return schema.id === "/gateway/schema/boeketcadeau2014?localAction=deliveryAddress"
      ? context.validRecipientZipcode
      : context.validCustomerZipcode;
  }

  // customer profile form (?)
  if (!address.countryCode || address.countryCode !== "NL" || !address.zipcode) {
    return true;
  }
  return address.zipcode.match(/^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/) !== null;
});

/**
 * Foreign orders need a phone number!
 */
env.addCheck("checkAddressPhoneRequired", (address) => {
  if (!address || !address.countryCode || address.countryCode === "NL") {
    return true;
  }

  return address.phone && address.phone.length;
});

// KMT-21887 - Order als particulier op rekening ontvangen
env.addCheck("checkPaymentType", (cart) => {
  const invalidTypes = {
    person: [943243], // : "Op rekening"
    company: [1], // : "After Pay"
  };
  if (
    cart &&
    cart.address &&
    cart.address.customer &&
    cart.address.customer.type &&
    cart.address.customer.countryCode &&
    cart.payment &&
    cart.payment.type
  ) {
    // https://trello.com/c/zL7t1Ke2/20-bij-factuuradres-buiten-nederland-nooit-op-rekening-laten-kiezen
    if (cart.address.customer.countryCode !== "NL" && cart.payment.type === 943243) {
      return false;
    }

    // only allow bancontact in belgium
    if (cart.address.customer.countryCode !== "BE" && cart.payment.type === 22563083) {
      return false;
    }

    // only allow sofort outside of belgium and the netherlands
    if (["NL", "BE"].indexOf(cart.address.customer.countryCode) > -1 && cart.payment.type === 22563060) {
      return false;
    }

    return invalidTypes[cart.address.customer.type].indexOf(cart.payment.type) === -1;
  }
  return false;
});

env.addCheck(
  "checkAfterpaymentOption",
  (paymentType) => !(paymentType.type === 1 && (!paymentType.afterpayment || paymentType.afterpayment < 1))
);

env.addCheck("checkDeliveryDateAndTimeFrame", (cart, whitney, schema, path, context) => {
  if (cart && cart.delivery) {
    if (cart.delivery.date && context.availableDates.indexOf(cart.delivery.date) < 0) {
      return false;
    }

    if (cart.delivery.timeFrame && map(context.availableTimeFrames, "id").indexOf(cart.delivery.timeFrame) < 0) {
      return false;
    }
  }
  return true;
});

/**
 * remove all non digits then check it is at least 10 numbers
 */
env.addCheck("phoneNumber", (value) => {
  if (!value || !value.replace) {
    return true;
  }
  return value.replace(/\D+/g, "").length >= 10;
});

env.addCheck("checkMinimumQuantity", (value, whitney, schema, path, context) => {
  let valid = true;
  if (value && value.productCollectionMemberId && value.quantity && context && context.itemContexts) {
    $.each(context.itemContexts, (key, itemContext) => {
      if (
        value.productCollectionMemberId === itemContext.id &&
        itemContext.minimumQuantity &&
        itemContext.minimumQuantity > value.quantity
      ) {
        valid = false;
      }
      return valid;
    });
  }
  return valid;
});

env.addCheck("checkWebProductPublished", (value, whitney, schema, path, context) => {
  let valid = true;
  if (value && value.productCollectionMemberId && context && context.itemContexts) {
    $.each(context.itemContexts, (key, itemContext) => {
      if (value.productCollectionMemberId === itemContext.id && !itemContext.isPublished) {
        valid = false;
      }
      return valid;
    });
  }
  return valid;
});

env.addCheck("checkWebProductNotSoldOut", (value, whitney, schema, path, context) => {
  let valid = true;
  if (value && value.productCollectionMemberId && context && context.itemContexts) {
    $.each(context.itemContexts, (key, itemContext) => {
      if (value.productCollectionMemberId === itemContext.id && itemContext.isSoldOut) {
        valid = false;
      }
      return valid;
    });
  }
  return valid;
});

env.addCheck("checkValidDeliveryDate", (value, whitney, schema, path, context) => {
  if (!value || !value.replace) {
    return true;
  }
  if (context.availableDates && context.availableDates.length) {
    return context.availableDates.indexOf(value) !== -1;
  }
  return true;
});

const isValid = (pathNibbles, errors) => !hasIn(errors, pathNibbles);

export default {
  jjvOptions: {
    useDefault: true,
  },

  getEnvironment() {
    return $.when(...subSchemaPromises).then(() => env);
  },

  toggleErrorClass(fieldName, validation) {
    const $input = $(`[name="${fieldName}"]:first`);
    const $target = $input.is("[type=radio]") ? $input.parents(".radiogroup").parent() : $input.parent();
    const path = (fieldName.substr(0, 5) === "root[" ? fieldName.substr(5, fieldName.length - 6) : fieldName).split(
      "]["
    );
    $target.toggleClass("error", !isValid(path, validation));
  },

  scrollToFirstError() {
    let firstError = $(".error:visible:first");
    if (!firstError.length) {
      firstError = $(".formErrors");
    }
    if (!firstError.length) {
      // just scroll to the content ?
      firstError = $(".contentContainer");
    }
    scroll.scrollTo(firstError.offset().top, 1000);
  },
};
