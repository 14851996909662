import extend from "lodash/assignIn";
import BaseView from "./Base";
import $ from "jquery";
import Handlebars, { getTemplate } from "../global/renderEngine";

const templateSource = getTemplate("cart/items");

export default BaseView.extend({
  events: {
    'input input[type="number"]': "updateCartQuantityByInput",
    'change input[type="number"]': "updateCartQuantityByInput",
    "click .delete a": "deleteRow",
    "click .delete": "deleteRow",
  },

  template: Handlebars.compile(templateSource),

  render(context) {
    const self = this;
    const templateData = extend({}, this.model.toJSON(), context);

    $.each(context.itemContexts, (index, itemContext) => {
      templateData.items[index] = extend(templateData.items[index] || {}, itemContext);
    });
    this.$el.html(this.template(templateData));
    self.trigger("render");
    return this;
  },

  updateCartQuantityByInput(e) {
    const $input = $(e.target);

    if ($input.hasClass("cardQuantity")) {
      this.model.get("card").set("quantity", parseInt($input.val(), 10));
      return;
    }

    if ($input.hasClass("chocolateBarQuantity")) {
      this.model.get("card").set("chocolateBarQuantity", parseInt($input.val(), 10));
      return;
    }

    this.updateModel(e);
  },

  deleteRow(e) {
    const $tr = $(e.target).parents("tr");
    const $tbody = $tr.parent();
    if ($tr.is(".chocolateBarRow")) {
      this.model.get("card").set("chocolateBarQuantity", 0);
      return false;
    }
    const items = this.model.get("items");
    items.remove(items.at($tbody.children("tr").index($tr)));
    return false;
  },
});
