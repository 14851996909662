import Backbone from "backbone";
import $ from "jquery";
import Handlebars, { getTemplate } from "../global/renderEngine";
import "../global/form/validator";

const templateSource = getTemplate("profile/addressBook");

export default Backbone.View.extend({
  events: {
    "submit .addressBookSearchForm": "search",
    "click .addressBook .row div,  .addressBook .row .editButton": "editAddress",
    "click .addressBook .deleteButton": "deleteAddress",
    "change #addressType": "updateModelAndRender",
    "change form.deliveryAddress #zipcode": "enrichByZipcode",
    "submit form.deliveryAddress": "submit",
  },

  template: Handlebars.compile(templateSource),

  render: function () {
    const self = this;
    $.get("/gateway/addressBook", (result) => {
      self.$el.html(self.template(result));
      self.trigger("render");
    });
    return this;
  },

  search: function (e) {
    const self = this;
    $.get("/gateway/addressBook", $(e.target).serialize(), (result) => {
      self.$el.html(self.template(result));
    });
    return false;
  },

  editAddress: function (e) {
    Backbone.history.navigate($(e.target).parents(".row").find(".editButton").prop("href").split("#")[1], {
      trigger: true,
    });
    return false;
  },

  deleteAddress: function (e) {
    const self = this;
    const id = $(e.target).prop("href").split("/").pop();
    if (window.confirm("Weet u zeker dat u dit wilt verwijderen?")) {
      $.get(
        "/gateway/addressBook",
        {
          localAction: "delete",
          id: id,
        },
        (response) => {
          if (response.success) {
            self.render();
            return;
          }
          window.alert("Er gaat iets mis. Neem contact op met Boeketcadeau indien dit probleem aanhoudt");
        }
      );
    }
    return false;
  },
});
