import $ from "jquery";
import catalogUtils from "../catalog/utils";
import * as globalUtils from "../../global/utils";

$.fn.fullPath = function getJQPath() {
  return $(this)
    .parentsUntil("body")
    .andSelf()
    .map(function classDoer() {
      const classes = this.getAttribute("class").replace(/\s+/, ".");
      return this.nodeName + (classes.length > 0 ? `.${classes}` : "");
    })
    .get()
    .join(" > ");
};

function addCSSRule(sheet, selector, rules, index) {
  try {
    if ("insertRule" in sheet) {
      sheet.insertRule(`${selector}{${rules}}`, index || sheet.cssRules.length);
    } else if ("addRule" in sheet) {
      sheet.addRule(selector, rules, index || sheet.cssRules.length);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

$.fn.reverse = [].reverse;

export default {
  init() {
    const $body = $("body");
    const $productsBar = $(".productsBar");

    if ($body.data("is-administrator")) {
      $productsBar.find("li").click(function productsBarClick() {
        // eslint-disable-next-line no-alert
        if (window.confirm("U bent ingelogd als Administrator. Wilt u dit product direct in het KMT bewerken?")) {
          window.location = `/kmt/webProduct/?localAction=edit&id=${$(this).attr("id").split("_")[1]}`;
          return false;
        }
        return true;
      });
    }

    this.initOfferBars();

    $body.on("click", ".productsBar .show360Button", (event) => {
      event.preventDefault();
      catalogUtils.openFancyBox({
        action: "360",
        dataContainer: $(event.currentTarget).parent(),
        enableOrderLink: true,
      });
      return false;
    });
  },

  initOfferBars() {
    const $offerBars = $(".offerBar");

    if ($offerBars.length > 0 && !globalUtils.isMobileVisit()) {
      // reverse so that the one displayed is displayed first...
      $offerBars.reverse().each(function initAnOfferBar() {
        const $offerBar = $(this);

        const $offer = $offerBar.find(".offer");
        if ($offer.length === 0) {
          return;
        }

        const foregroundColor = $offer.data("foregroundcolor");
        const imagefileversionid = $offer.data("imagefileversionid");

        let bgImageUrl = `/download/?id=${$offer.data("imagefileid")}`;
        if (imagefileversionid) {
          bgImageUrl += `&versionId=${imagefileversionid}&ttl=16070400&q=80`;
        }
        bgImageUrl += "&fileType=auto";

        const height = $offerBar.parents(".categoryHoverOuter").length ? "235px" : "362px";

        $offerBar
          .css({
            "background-color": $offer.data("backgroundcolor"),
            height,
            "min-height": height,
            color: "#FFF", // #KMT-21180
          })
          .attr("data-background-image-url", bgImageUrl)
          .lazyLoad();

        $offer.find(".h3, p, .p").css({
          "background-color": foregroundColor,
          color: "#FFF", // #KMT-21180
        });

        // hack for adding pseudo element css rules
        $.each(document.styleSheets, (idx, stylesheet) => {
          addCSSRule(stylesheet, `${$offer.fullPath()} .h3:after`, `border-left-color: ${foregroundColor}`);
        });
      });
    }
  },
};
