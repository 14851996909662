import find from "lodash/find";
import $ from "jquery";
import fancybox from "fancybox/dist/js/jquery.fancybox.cjs";
import { isMobileVisit } from "../../global/utils";
import globalCart from "../../global/cart";

fancybox($);
window.$ = $;

export default {
  parsePriceFromInput: function ($input) {
    return this.parsePrice($input.val().trim()) / 100; // 950 -> 9.50
  },

  /**
   * Make user currency input into number of cents.
   *
   * @param val
   * @returns {Number}
   */
  parsePrice: function (val) {
    let _val = `${val}`.trim(); // stringify

    if (!/[.,]/.test(_val)) {
      // 999 -> 99900
      _val += "00";
    } else {
      if (/.*[.,][0-9]$/.test(_val)) {
        // 9.5 -> 950
        _val += "0";
      }
    }

    return parseInt(_val.replace(/\D/g, ""), 10);
  },

  price: function (number, prefix = "€") {
    const decimals = 2;
    const decPoint = ",";
    const thousandsSep = ".";

    number = `${number}`.replace(/[^0-9+\-Ee.]/g, "");
    const n = !isFinite(+number) ? 0 : +number;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
    const dec = typeof decPoint === "undefined" ? "." : decPoint;

    function toFixedFix(n, prec) {
      const k = Math.pow(10, prec);
      return `${(Math.round(n * k) / k).toFixed(prec)}`;
    }

    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    const s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split(".");
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return prefix + s.join(dec);
  },

  /**
   * Possible options
   *
   * - action {string} - zoom | 360
   * - dataContainer {jQuery} - to retrieve data from
   * - enableOrderLink - if order button should be added in the pop-up
   *
   * @param options
   */
  openFancyBox: function (options) {
    const me = this;

    const fancyboxConfig = {
      tpl: {
        wrap: '<div class="fancybox-wrap" tabIndex="-1"><a href="#" class="closeButton">Sluiten</a>',
      },
      closeBtn: false,
      margin: [50, 20, 20, 20], //extra margin for orderNow button
      title: options.dataContainer.data("title"),
    };

    // 360 exists? add it
    if (options.dataContainer.data("360")) {
      fancyboxConfig.tpl.wrap += '<a href="#" class="show360Button">360</a>';
    }

    fancyboxConfig.tpl.wrap +=
      '<div class="fancybox-skin"><div class="fancybox-outer">' +
      '<div class="fancybox-inner"></div></div></div></div>';

    let width;
    let height;
    if (options.dataContainer.data("orientation") === "portrait") {
      width = isMobileVisit() ? 173 : 339;
      height = isMobileVisit() ? 260 : 510;
    } else {
      width = isMobileVisit() ? 219 : 500;
      height = isMobileVisit() ? 219 : 500;
    }

    switch (options.action) {
      case "zoom":
        fancyboxConfig.type = "image";
        // eslint-disable-next-line prefer-destructuring
        fancyboxConfig.href = $("img", options.dataContainer).prop("src").split("&")[0];
        break;

      case "360":
        if (typeof ga !== "undefined") {
          window.ga("send", "event", "view", "360-afbeelding");
        }
        fancyboxConfig.type = "iframe";
        fancyboxConfig.href = `https://static.boeketcadeau.com/optim/${width}x${height}/${encodeURIComponent(
          options.dataContainer.data("360")
        )}/index.html`;
        break;

      default:
        console.log(`Unsupported action ${options.action}`);
    }

    fancyboxConfig.afterShow = function () {
      window.setTimeout(() => {
        const $wrap = $(this.wrap);
        const $titleContainer = $wrap.find(".fancybox-title");

        if (options.enableOrderLink) {
          $titleContainer.append(
            `<a href="${$("a.orderLink", options.dataContainer).prop(
              "href"
            )}" class="orderButton" style="display: block; position: absolute; right: 0; bottom: 0;${
              options.dataContainer.data("color") ? ` background-color: #${options.dataContainer.data("color")};` : ""
            }">${$(".orderButton", options.dataContainer).html()}</a>`
          );
          $titleContainer.find(".child").css({
            float: "left",
            "padding-bottom": "10px",
            width: `${$wrap.outerWidth() - $titleContainer.find("a").outerWidth()}px`,
          });
        }

        $titleContainer.css({
          width: "100%",
          top: `${-$titleContainer.outerHeight()}px`,
        });
      }, 1);
    };

    fancyboxConfig.afterLoad = function () {
      $.extend(this, { width: width + 10, height: height + 10 });

      const $wrap = $(this.wrap);
      $wrap
        .on("click", ".closeButton", () => {
          //event
          if ($.fancybox && $.fancybox.close) {
            $.fancybox.close();
          }
          return false;
        })
        .on("click", ".show360Button", () => {
          //event
          if (fancyboxConfig.type === "image") {
            options.action = "360";
            me.openFancyBox(options);
            return false;
          }
          const $iframe = $wrap.find(".fancybox-iframe");
          if ($iframe.length !== 1) {
            return false;
          }
          $iframe[0].src = $iframe[0].src; // eslint-disable-line
          return false;
        });
    };

    $.fancybox(fancyboxConfig);
  },

  addVase: function (cartItem) {
    return $.when(globalCart.get(), globalCart.getContext()).then((cart, context) => {
      //find the context for the target cartItem
      const itemContext = find(
        context.itemContexts,
        (_itemContext) => _itemContext.id === cartItem.get("productCollectionMemberId")
      );

      if (itemContext) {
        cart.get("items").add({
          productCollectionMemberId: itemContext.vaseProductCollectionMemberId,
          quantity: 1,
        });
      }
    });
  },
};
