import $ from "jquery";
import * as utils from "../utils";

const renderEnum = (propConfig, path, value) => {
  const chunk = [];
  const id = path.join("-");
  const name = `root[${path.join("][")}]`;
  if (!propConfig.enumTitles) {
    propConfig.enumTitles = {};
  }
  if (value === undefined && propConfig.default !== undefined) {
    value = propConfig.default;
  }

  switch (propConfig.inputType) {
    case "radio":
      chunk.push('<div class="radiogroup">');
      $.each(propConfig.enum, (key, radioValue) => {
        const radioId = `${id}-${radioValue}`;
        chunk.push("<div>");
        chunk.push(
          `<input type="radio" name="${name}" value="${radioValue}" id="${radioId}"${
            radioValue === value ? ' checked="checked"' : ""
          }><label for="${radioId}">${propConfig.enumTitles[radioValue] || radioValue}</label>`
        );
        chunk.push("</div>");
      });
      chunk.push("</div>");
      break;

    default:
      chunk.push(`<select name="${name}" id="${id}" class="radiogroup">`);
      $.each(propConfig.enum, (key, optionValue) => {
        chunk.push(
          `<option value="${optionValue}"${optionValue === value ? " selected" : ""}>${
            propConfig.enumTitles[optionValue] || optionValue
          }</option>`
        );
      });
      chunk.push("</select>");
  }

  return chunk.join("");
};

const renderChunks = (chunkPromises) => $.when(...chunkPromises).then((...args) => $.makeArray(args).join(""));

const renderChunk = (path, propConfig, value) => {
  const propName = path.pop();
  const id = (path.length ? `${path.join("-")}-` : "") + propName;
  const chunk = [`<div class="${propName}" data-datatype="${propConfig.type}">`];
  const subPath = path.slice(0);
  const name = `root${path.length ? `[${path.join("][")}]` : ""}[${propName}]`;
  subPath.push(propName);
  let placeHolder = "";
  if (propConfig.placeHolder || propConfig.title) {
    placeHolder = `placeholder="${propConfig.placeHolder || propConfig.title}"`;
  }

  if (value === undefined && propConfig.default !== undefined) {
    value = propConfig.default;
  }
  const valueAsString = value || "";

  switch (propConfig.type) {
    case undefined: // complex type
    case "object":
      chunk.push('<div class="fieldset">');
      if (propConfig.title) {
        chunk.push(`<div class="legend">${propConfig.title}</div>`);
      }

      // eslint-disable-next-line no-use-before-define
      chunk.push(renderForm(propConfig, subPath, value));
      chunk.push("</div>");
      break;

    case "number":
      // check for "explicit empty"
      if (propConfig.title !== "") {
        chunk.push(`<label for="${id}">${propConfig.title ? propConfig.title : propName}</label>`);
      }
      chunk.push(
        propConfig.enum === undefined
          ? `<input type="${propConfig.inputType || "number"}" name="${name}" id="${id}" value="${valueAsString}">`
          : renderEnum(propConfig, subPath, value)
      );
      break;

    case "boolean":
      chunk.push(`<input type="checkbox" name="${name}" id="${id}" value="1"${value ? ' checked="checked"' : ""} />`);
      chunk.push(`<label for="${id}">${propConfig.title}</label>`);
      break;

    case "array":
      chunk.push('<div class="fieldset">');
      if (propConfig.title) {
        chunk.push(`<div class="legend">${propConfig.title}</div>`);
      }
      $.each(value, (key, subData) => {
        const itemSubPath = subPath.slice(0);
        itemSubPath.push(key);
        // eslint-disable-next-line no-use-before-define
        chunk.push(renderForm(propConfig.items, itemSubPath, subData));
      });
      chunk.push("</div>");
      break;

    case "string":
      {
        const isEnum = propConfig.enum !== undefined;
        if (propConfig.title) {
          chunk.push(`<label for="${id}">${propConfig.title}${!isEnum && propConfig.minLength ? " *" : ""}</label>`);
        }

        if (isEnum) {
          chunk.push(renderEnum(propConfig, subPath, value));
        } else {
          chunk.push(
            propConfig.inputType === "textarea"
              ? `<textarea type="text" name="${name}" id="${id}" ${placeHolder}>${valueAsString}</textarea>`
              : `<input type="${
                  propConfig.inputType || propConfig.format || "text"
                }" name="${name}" id="${id}" value="${valueAsString}" ${placeHolder}>`
          );
        }
      }
      break;

    default:
      chunk.push(`<label for="${id}">${propConfig.title}</label>`);
      chunk.push(
        `<input type="${
          propConfig.inputType || propConfig.format || "text"
        }" name="${name}" id="${id}" value="${valueAsString}" ${placeHolder}>`
      );
  }

  chunk.push("</div>");
  return renderChunks(chunk);
};

/**
 *
 * @param {object} schema
 * @param {array} path
 * @param {object} data
 *
 * @returns {Promise}
 */
const renderForm = (schema, path, data) => {
  const chunkPromises = [];
  data = data || {};

  if (schema.properties === undefined) {
    if (schema.allOf) {
      $.each(schema.allOf, (key, subSchema) => {
        chunkPromises.push(renderForm(subSchema, path, data));
      });
      return renderChunks(chunkPromises);
    }

    if (schema.$ref) {
      return utils.getCachedXhrPromise("get", schema.$ref).then((subSchema) => {
        if (subSchema.properties) {
          // e.g. cart.customer?
          return renderForm(subSchema, path, data);
        }
        // e.g. cart.payment?
        return renderChunk(path, subSchema, data);
      });
    }

    return renderChunks(chunkPromises);
  }

  $.each(schema.properties, (propName, propConfig) => {
    path.slice(0);
    path.push(propName);
    chunkPromises.push(renderChunk(path, propConfig, data[propName]));
  });
  return renderChunks(chunkPromises);
};

export default {
  render: renderForm,
  renderChunk,
};
