import isString from "lodash/isString";
import $ from "jquery";
import "form-serializer";

const getValue = function ($input) {
  if ($input.is(":checkbox")) {
    return $input.is(":checked");
  }

  const isRadio = $input.is(":radio");
  const isChecked = $input.is(":checked");
  if (isRadio && !isChecked) {
    return null;
  }

  //typecasting / coercion is done via datatype data attributes, if available
  let value = $input.val();
  const parentWithDataTypeHint = $input.parents("[data-datatype]");
  if (parentWithDataTypeHint.length) {
    switch (parentWithDataTypeHint.data("datatype")) {
      case "number":
        value = value && value.replace ? parseFloat(value.replace(/[^\d.-]/g, "")) : 0;
        break;
      case "boolean":
        value = isString(value) && value === "1";
        break;
      default:
      //nothing
    }
  }

  return value;
};

/**
 * proprietary code
 *
 * @param $inputs
 * @param parseAtPath
 * @returns {*}
 */
const parseInputs = function ($inputs, parseAtPath) {
  const result = {};

  const hydrate = function (path, value, currentNode) {
    if (!currentNode) {
      currentNode = result;
      path = path.slice(0);
    }
    const nextNibble = path.shift();
    if (path.length === 0) {
      if (currentNode[nextNibble] === undefined) {
        currentNode[nextNibble] = value;
      }
      return;
    }
    if (currentNode[nextNibble] === undefined) {
      currentNode[nextNibble] = path[0].replace(/\d/g, "").length === 0 ? [] : {};
    }
    hydrate(path, value, currentNode[nextNibble]);
  };

  //works for non-forms too!
  $inputs.each(function () {
    const $input = $(this);
    let path = $input
      .prop("name")
      .replace(/^[[\]]+|[[\]]+$/g, "")
      .split(/[[\]]+/g);
    if (path[0] === "root") {
      path.shift();
    }

    if (parseAtPath && parseAtPath.length) {
      path = path.slice(parseAtPath.length);
    }

    const value = getValue($input);
    if (value !== null) {
      hydrate(path, value);
    }
  });

  //any array_ish value may have missing keys now... Fix that!
  const fixArrays = function (collection) {
    const isArray = $.isArray(collection);
    const isPlainObject = $.isPlainObject(collection);

    if (isArray || isPlainObject) {
      $.each(collection, (key, value) => {
        collection[key] = fixArrays(value);
      });
    }

    if (isArray) {
      const newArray = [];
      $.each(collection, (key, value) => {
        newArray.push(value);
      });
      return newArray;
    }

    return collection;
  };

  //dereferrence returned cached values
  return fixArrays(result);
};

const parseForm = function ($form) {
  return parseInputs($form.find(":input"));
};

/**
 * Uses https://github.com/macek/jquery-serialize-object
 *
 * @todo required type coercion
 *
 * @param $form
 * @param parseAtPath
 * @returns {*}
 */
function thirdPartyParse($form, parseAtPath) {
  return $form.serializeObject();
}

export default {
  parseForm: parseForm, // default is proprietary (current logic)
  parseInputs: parseInputs, // currently exposed for testing
  thirdPartyParse: thirdPartyParse, // currently exposed for testing
};
