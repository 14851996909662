import Backbone from "backbone";
import $ from "jquery";
import Handlebars, { getTemplate } from "../../global/renderEngine";

const templateSource = getTemplate("profile/orderList");

export default Backbone.View.extend({
  events: {
    // specific click on any div to make sure the click on the "a" is still honoured
    // the reason for this is the ".row > a" opens a pdf in a separate tab (target="_blank")
    // unfortunately this ensures that clicking on whitespace fails...
    "click .row > div": "doToggleOrderItemsDisplay",
  },

  template: Handlebars.compile(templateSource),

  render: function () {
    const self = this;
    $.get("/gateway/order", (result) => {
      self.$el.html(self.template(result));
      self.trigger("render");
    });
    return this;
  },

  doToggleOrderItemsDisplay: function (event) {
    // specific click on any div to make sure the click on the "a" is still honoured
    // therefore we need to go one back to get the parent to find the orderItems
    $(event.currentTarget).parent().find(".orderItems").toggle();
    return false;
  },
});
