import Backbone from "backbone";
import $ from "jquery";
import formParser from "../global/form/parser";
import formBinding from "../global/form/binding";
import formValidator from "../global/form/validator";

export default Backbone.View.extend({
  schemaId: "** NOT CONFIGURED **",

  getSchemaData() {
    return formParser.parseForm(this.$el, this.path || []);
  },

  updateModel(e) {
    const self = this;

    if (e) {
      self.activeElementId = $(e.currentTarget).prop("id");
    } else {
      self.activeElementId = undefined;
    }

    // if an event is passed, the update comes from a secific input: only update _that_ value
    if (e) {
      formBinding.assignValueByInput(self.model, $(e.currentTarget), this.path);
    } else {
      self.model.set(this.getSchemaData());
    }
  },

  triggerReactivateFocus($el) {
    const self = this;
    setTimeout(() => {
      const $input = $el || self.$el.find(`#${self.activeElementId}`);
      self.activeElementId = undefined;
      if ($input.length === 0) {
        return;
      }

      // force focus
      $input.focus();

      if ($input.prop("type") !== "text") {
        return;
      }

      // force reselection (to end)
      $input.prop("selectionStart", $input.val().length).prop("selectionEnd", $input.val().length);
    }, 1);
  },

  updateModelAndRender(e) {
    this.updateModel(e);
    return this.render();
  },

  // maybe used for special rendering, e.g. multiple addresses found on a certain zipcode
  lastZipcodeLookupResults: null,

  updateAddressByZipcode() {
    const address = this.model;
    const self = this;

    // reset any last zipcode stuff
    self.lastZipcodeLookupResults = null;

    if (address.get("countryCode") !== "NL") {
      return;
    }

    const zipcode = address.get("zipcode");
    if (!zipcode) {
      return;
    }

    $.get(`/gateway/pro6pp?zipcode=${zipcode}`, (data) => {
      if (data.results && data.results.length > 0) {
        if (data.results[0].street === "Postbus" && address.get("addressType")) {
          self.$(".zipcode").toggleClass("error", true);
          window.alert("Bezorgen bij een postbus is helaas niet mogelijk. Graag een vestigingsadres invullen.");
          return;
        }

        self.lastZipcodeLookupResults = data.results;

        if (data.results.length > 1) {
          // KMT-21880 - Pop up maken bij meerdere straten bij een postcode
          window.alert("Let op, voor deze postcode zijn meerdere adressen mogelijk.");
        }

        address.set("city", data.results[0].city);
        address.trigger("pro6pp_change:city");

        // setting the street triggers render! _SET-ORDER IS RELEVANT_!
        address.set("street", data.results[0].street);
        address.trigger("pro6pp_change:street");

        self.once("render", () => {
          self.triggerReactivateFocus(self.$("[id^=address-][id$=-streetNumber]"));
        });

        return;
      }
      self.once("render", () => {
        self.$(".zipcodeHelp").show();
        self.$(".zipcode").toggleClass("error", true);
      });
      self.render();
    });
  },

  resetAddressError(e) {
    if (this.$(".zipcodeHelp").is(":visible")) {
      const zipcode = $(e.target)
        .val()
        .replace(/[^0-9a-z]/gi, "");
      // valid nl zipcode?
      if (zipcode.match(/^\d{4}[a-z]{2}$/)) {
        // apply to the model! (that triggers re-rendering of the form)
        this.model.set("zipcode", zipcode);
      }
    }
  },

  patchStreetAndCityInputs() {
    const zipcode = this.model.get("zipcode");
    this.$(".street, .city").toggle(this.model.get("countryCode") !== "NL" || (zipcode && zipcode.length > 0));
    this.$(".zipcode").before(
      '<div class="zipcodeHelp">De gebruikte postcode is onjuist.' +
        " U kunt zoeken naar de juiste postcode op" +
        ' <a href="http://www.postcode.nl" rel="noreferrer noopener" target="_blank" >http://www.postcode.nl</a></div>'
    );

    // The address input should be a select when multiple streets are found e.g.6815AZ
    // KMT-21387 - Postcode check BoeketCadeau bij meerdere straten 1 postcode
    if (this.lastZipcodeLookupResults && this.lastZipcodeLookupResults.length > 1) {
      const $originalInput = this.$(".street input");
      const $newInput = $(`<select name="${$originalInput.prop("name")}" id="${$originalInput.prop("id")}"></select>`);
      $.each(this.lastZipcodeLookupResults, (key, result) => {
        $newInput.append(
          `<option${result.street === $originalInput.val() ? ' selected="selected"' : ""}>${result.street}</option>`
        );
      });
      $originalInput.replaceWith($newInput);
    }
  },

  /**
   * @returns {Promise} for data
   */
  validate() {
    const self = this;
    const deferred = $.Deferred();
    formValidator.getEnvironment().then((env) => {
      const schemaData = self.getSchemaData();
      const errors = env.validate(self.schemaId, schemaData, formValidator.jjvOptions);
      if (errors && errors.validation) {
        self.$el.find("input[name], textarea[name], select[name]").each(function e() {
          formValidator.toggleErrorClass($(this).prop("name"), errors.validation);
        });
        formValidator.scrollToFirstError();
        return deferred.reject(errors);
      }
      return deferred.resolve(schemaData);
    });
    return deferred.promise();
  },
});
