import $ from "jquery";
import Backbone from "backbone";
import catalogUtils from "./utils";
import formParser from "../../global/form/parser";
import formValidator from "../../global/form/validator";
import * as utils from "../../global/utils";
import globalCart from "../../global/cart";
import Handlebars, { getTemplate } from "../../global/renderEngine";
import "jquery-ui/ui/effect";
import "jquery-ui/ui/effects/effect-transfer";
import "readmore-js";
import "jquery-ui/ui/widgets/dialog";

const templateSource = getTemplate("cart/navigation");

let $extraProductsFooter;
let $webProductDetails;
let $navigation;
let $productCategories;
let originalMenu;
const navigationTemplate = Handlebars.compile(templateSource);

function init() {
  $navigation = $(".navigation");
  $extraProductsFooter = $(".extraProductsFooter");
  $webProductDetails = $(".webProductDetails");
  $productCategories = $(".productCategories");

  const $extraReviewsRow = $(".extraReviewsRow");

  if ($extraReviewsRow.length) {
    const $detailsColumn = $(".footerContent .leftColumn");
    const $reviewsColumn = $(".footerContent .rightColumn");

    $reviewsColumn.find(".reviews").append(
      $extraReviewsRow
        .find("li")
        .slice(0, Math.floor($detailsColumn.height() / $reviewsColumn.height()) * 2)
        .detach()
    );

    $('<a href="#" style="float: left; padding: 8px;">Lees meer</a>')
      .appendTo(".webProductDetailsFooter .footerContent .rightColumn")
      .click(function () {
        $extraReviewsRow.show();
        $(this).hide();

        const $moreExtraReviews = $(".extraReviewsRow li:gt(7)");
        if ($moreExtraReviews.length > 0) {
          $moreExtraReviews.hide();
          $('<a href="#" style="float: left; padding: 8px;">Lees meer</a>')
            .appendTo($extraReviewsRow)
            .click(function () {
              $moreExtraReviews.show();
              $(this).hide();
              return false;
            });
        }
        return false;
      });
  }

  const $description = $(
    ".fruitmanden .webProductDetails .description > div, .vandaagbloemen .webProductDetails .description > div"
  );
  if ($description.length > 0 && $description.readmore) {
    $description.readmore({
      maxHeight: 130,
      moreLink: '<a class="btn-readmore-more" href="#">Lees meer</a>',
      lessLink: '<a class="btn-readmore-less" href="#">Minder</a>',
    });
  }

  return $.when(globalCart.get(), formValidator.getEnvironment()).then((cart, env) => {
    // WebProduct details page
    const $orderForm = $(".webProductDetails");
    const $vaseSelectCheckbox = $("#vase");
    const $productCollectionMemberIdInputs = $('[name="productCollectionMemberId"]');
    const $productCollectionMemberIdCustom = $("#productCollectionMemberId_custom");
    const $productCollectionMemberIdCustomQuantity = $("#productCollectionMemberId_customQuantity");
    const $productCollectionMemberIdFirst = $("[name=productCollectionMemberId]:first");
    const $customPrice = $("#customPrice");
    const $customQuantity = $("#quantity");

    const animationProps = { duration: 1200, queue: false };

    const updateVasePrice = function ($productCollectionMemberId) {
      const isCustomPrice = $productCollectionMemberId.is("#productCollectionMemberId_custom");
      const isCustomQuantity = $productCollectionMemberId.is("#productCollectionMemberId_customQuantity");
      const requestParams = {
        inc: 1,
        localAction: "getVasePrice",
      };

      if (isCustomPrice) {
        requestParams.price = catalogUtils.parsePriceFromInput($customPrice);
        if (requestParams.price === 0) {
          return;
        }
      } else {
        requestParams.productCollectionMemberId = isCustomQuantity
          ? $productCollectionMemberIdFirst.val()
          : $productCollectionMemberId.val();
        if (isCustomQuantity) {
          requestParams.quantity = parseInt($customQuantity.val().replace(/\D/g, ""), 10);
        }
      }

      if ($vaseSelectCheckbox.length) {
        $.get("/gateway/flowerShop", requestParams, (price) => {
          $vaseSelectCheckbox.parent().find(".price").text(catalogUtils.price(price));
        });
      }
    };

    function validateCustomPrice() {
      if (catalogUtils.parsePriceFromInput($customPrice) * 100 < parseInt($customPrice.data("min"), 10)) {
        return {
          validation: {
            customPrice: {
              outOfBounds: true,
            },
          },
        };
      }
      return null;
    }

    const customPriceValidator = function () {
      if (
        !$productCollectionMemberIdCustom.is(":checked") ||
        $customPrice.val() === "" ||
        validateCustomPrice() === null
      ) {
        $customPrice.parents(".customPrice").removeClass("error").find(".error.message").hide();
      } else {
        $customPrice.parents(".customPrice").addClass("error").find(".error.message").show();
      }
    };

    function updateCustomQuantityPrice() {
      const quantity = parseInt($(this).val(), 10);
      if (quantity > 0) {
        const pricePerProduct = $productCollectionMemberIdFirst.data("price-per-product");
        $(this)
          .parent()
          .find(".price")
          .text(catalogUtils.price(quantity * pricePerProduct));
        updateVasePrice($productCollectionMemberIdCustomQuantity);
      }
    }

    $productCollectionMemberIdCustom.change(() => {
      $customPrice.focus();
      return true;
    });

    $customPrice.focus(() => {
      if (!$productCollectionMemberIdCustom.prop("checked")) {
        $productCollectionMemberIdCustom.prop("checked", true).change();
      }
      if ($customPrice.val() === "") {
        $customPrice.parent().removeClass("error");
      }
    });

    $productCollectionMemberIdInputs.change(function () {
      const $this = $(this);
      updateVasePrice($this);
      $productCollectionMemberIdInputs.parent().removeClass("checked");
      $this.parent().addClass("checked");
      customPriceValidator();
    });

    $(".vaseSelect input").change(function () {
      const $this = $(this);
      $this.parent().toggleClass("checked", $this.is(":checked"));
    });

    // preselect only on desktop https://trello.com/c/kwn9OoNh/110-mouse-over-product#comment-57b4cf8f9e53dd
    if (!utils.isMobileVisit() && window.location && window.location.hash) {
      const selectedProductCollectionMemberId = parseInt(window.location.hash.substr(1), 10);
      if (selectedProductCollectionMemberId) {
        if ($customQuantity.length) {
          $productCollectionMemberIdCustomQuantity.prop("checked", true);
          $customQuantity.val(selectedProductCollectionMemberId);
          updateCustomQuantityPrice.apply($customQuantity);
        } else {
          $(`#productCollectionMemberId_${selectedProductCollectionMemberId}`).prop("checked", true);
        }
      }
    }
    $('[name="productCollectionMemberId"]:checked').change();
    $customPrice.on("change", customPriceValidator);

    const $productPreviewDiv = $(".productPreview").on("click", ".buttonZoom,.button360", (event) => {
      catalogUtils.openFancyBox({
        action: $(event.currentTarget).attr("href").replace("#", ""),
        dataContainer: $(event.delegateTarget),
      });
      return false;
    });

    if ($productPreviewDiv.data("360")) {
      window.setTimeout(() => {
        const url = `/gateway/webProduct?id=${$productPreviewDiv.data("360")}&p=${$productPreviewDiv.data(
          "orientation"
        )}`;
        $.get(url, (response) => {
          if (response) {
            $productPreviewDiv.append(
              `<iframe title="360 Animatie" frameborder="0" style="height: ${
                $productPreviewDiv.data("orientation") === "portrait" ? "461" : "310"
              }px;" allowfullscreen="" scrolling="no"` +
                ` src="https://static.boeketcadeau.com/optim/${
                  $productPreviewDiv.data("orientation") === "portrait" ? "300x451/" : "300x300/"
                }${$productPreviewDiv.data("360")}/index.html" />`
            );
            const $image = $productPreviewDiv.find("img");
            const $iframe = $productPreviewDiv.find("iframe");

            $image.hide();
            if (window.innerWidth < 800) {
              $iframe.on("load", () => {
                window.setTimeout(() => {
                  $image.show();
                  $iframe.hide();
                }, 3500);
              });
            }
          }
        });
      }, 2500);
    }

    function addQuantityByProductCollectionMemberId(productCollectionMemberId, toBeAddedQuantity) {
      const item = cart.get("items").findWhere({
        productCollectionMemberId: productCollectionMemberId,
      });
      if (item) {
        item.set("quantity", (item.get("quantity") || 0) + toBeAddedQuantity);
        return item;
      }
      return cart.get("items").add({
        productCollectionMemberId: productCollectionMemberId,
        quantity: toBeAddedQuantity,
      });
    }

    function validateCustomQuantity() {
      const quantity = parseInt($("#quantity").val().replace(/\D/g, ""), 10);
      if (quantity < $productCollectionMemberIdFirst.data("quantity")) {
        return {
          validation: {
            quantity: {
              outOfBounds: true,
            },
          },
        };
      }
      return null;
    }

    let customPriceVaseTimer;
    $customPrice.bind("keyup", () => {
      const price = catalogUtils.parsePriceFromInput($customPrice);
      $customPrice.parent().find("label.price").text(catalogUtils.price(price));
      clearTimeout(customPriceVaseTimer);
      customPriceVaseTimer = setTimeout(() => {
        updateVasePrice($productCollectionMemberIdCustom);
      }, 400);
    });

    $productCollectionMemberIdCustomQuantity.change(() => {
      $customQuantity.focus();
      return true;
    });
    $customQuantity.focus(() => {
      if (!$productCollectionMemberIdCustomQuantity.prop("checked")) {
        $productCollectionMemberIdCustomQuantity.prop("checked", true).change();
      }
    });
    $customQuantity.bind("keyup change", updateCustomQuantityPrice);

    const $orderButton = $orderForm.find(".orderButton:not(.orderButton--soldOut)");
    $orderForm.on("submit", () => {
      //Make sure we can enable the back-button to re-order stuff 3#31
      Backbone.history.navigate("addItem");

      $navigation.addClass("collapsed");
      originalMenu = $productCategories.html();
      $productCategories.html(navigationTemplate());

      //Prevent error in development environment
      if (typeof window._gaq !== "undefined") {
        //track it! #KMT-21279 - //do not use ```var```: _gaq is a global variable!
        window._gaq.push(["_trackPageview", window.location.href]);
      }

      const addProductCollectionMemberRequest = formParser.parseForm($orderForm);
      const checkedRadio = $("[name=productCollectionMemberId]:checked");
      const hasCustomPrice = checkedRadio.is("#productCollectionMemberId_custom");
      const hasCustomQuantity = checkedRadio.is("#productCollectionMemberId_customQuantity");
      let toBeAddedQuantity = checkedRadio.data("quantity") || 1;

      if (hasCustomPrice) {
        addProductCollectionMemberRequest.productCollectionMemberId = parseInt(
          $($productCollectionMemberIdInputs[$productCollectionMemberIdInputs.length - 2]).val(),
          10
        );
      } else {
        delete addProductCollectionMemberRequest.customPrice;
      }

      if (hasCustomQuantity) {
        addProductCollectionMemberRequest.productCollectionMemberId = parseInt(
          $productCollectionMemberIdFirst.val(),
          10
        );
      } else {
        delete addProductCollectionMemberRequest.quantity;
      }

      let errors = env.validate(
        "addProductCollectionMemberRequest",
        addProductCollectionMemberRequest,
        formValidator.jjvOptions
      );

      if (!errors && hasCustomQuantity) {
        errors = validateCustomQuantity();
      }

      if (!errors && hasCustomPrice) {
        errors = validateCustomPrice();
      }

      if (errors && errors.validation) {
        $orderForm.find("input[name], textarea[name], select[name]").each(function () {
          formValidator.toggleErrorClass($(this).prop("name"), errors.validation);
        });
        formValidator.scrollToFirstError();
        return false;
      }
      $("li.error").removeClass("error");

      //validation is done. Continue!
      $orderButton.prop("disabled", true);

      if (hasCustomQuantity) {
        toBeAddedQuantity = parseInt($("#quantity").val().replace(/\D/g, ""), 10);
      }

      // check if product is already in $globalCart, then confirm if yes continue if no rediredt
      const alreadyInCart = cart.get("items").findWhere({
        productCollectionMemberId: addProductCollectionMemberRequest.productCollectionMemberId,
      });

      function addToCart() {
        const item = addQuantityByProductCollectionMemberId(
          addProductCollectionMemberRequest.productCollectionMemberId,
          toBeAddedQuantity
        );
        if (hasCustomPrice) {
          item.set("customPrice", catalogUtils.parsePrice($("#customPrice").val()) / 100);
        }

        const processRequest = addProductCollectionMemberRequest.vase
          ? catalogUtils.addVase(item).then(() => globalCart.persist())
          : globalCart.persist();

        const $columns = $webProductDetails.find(".leftColumn, .rightColumn");
        processRequest
          .then(() => {
            if (alreadyInCart) {
              window.location = "/cart";
              return;
            }

            const effectPromise = $productPreviewDiv.effect("transfer", { to: ".menuCart" }, animationProps.duration);
            $(".ui-effects-transfer").css({
              background: `#FFF url(${$(".productPreview img").prop("src")}) no-repeat center top`,
              "-webkit-background-size": "contain",
              "-moz-background-size": "contain",
              "-o-background-size": "contain",
              "background-size": "contain",
            });

            //strip min-height setting for animation
            $webProductDetails.addClass("collapsed");
            return $.when(
              $(".productPreview, .preSubmit").animate({ opacity: 0 }, animationProps),
              $columns.animate({ height: 90 }, animationProps),
              effectPromise
            );
          })
          .then(() => {
            $orderButton.prop("disabled", false).hide();
            $columns.css({ height: "auto" });
            $orderForm.find("h1, .preSubmit").hide();
            $productPreviewDiv.hide();
            $(".webProductDetails .postSubmit, .buttonContainer").show();
            $(".webProductDetailsFooter").hide();
            $extraProductsFooter.show();
            if ($(".fruitmanden, .vandaagbloemen").length > 0) {
              $(".reviewBar").animate({ opacity: 0 }, animationProps).hide();
            }
            const $invisibleExtraProductLis = $("li:not(:visible)", $extraProductsFooter);
            const $showMoreLink = $(".showMore");
            if ($invisibleExtraProductLis.length > 0) {
              if (utils.isMobileVisit()) {
                $("html, body").scrollTop($(".crumbPath").offset().top);
              }
              $showMoreLink.click(() => {
                $invisibleExtraProductLis.show();
                $showMoreLink.hide();
                return false;
              });
            } else {
              $showMoreLink.hide();
            }
          });
      }

      if (!alreadyInCart) {
        addToCart();
        return false;
      }

      $("#confirm-readd-webproduct-and-redirect").dialog({
        resizable: false,
        height: "auto",
        width: utils.isMobileVisit() ? "100%" : 400,
        modal: true,
        buttons: {
          "Ja, voeg maar toe": function () {
            $(this).dialog("close");
            addToCart();
          },
          "Nee, ik wil afronden": function () {
            $(this).dialog("close");
            window.location = "/cart";
          },
        },
        close: function () {
          $orderButton.prop("disabled", false);
        },
      });
      return false;
    });
    $orderButton.prop("disabled", false);

    $(".footerContent .title").on("click", function () {
      if (!utils.isMobileVisit()) {
        return;
      }
      $(this).find(".fa").toggleClass("fa-flip-vertical");
      $(this).parent().toggleClass("expanded").find(".block-section").slideToggle();
    });

    $extraProductsFooter.on("click", "a", function (e) {
      if (e && e.target) {
        if (e.target.isClicked) {
          return false;
        }
        //noinspection JSPrimitiveTypeWrapperUsage
        e.target.isClicked = true;
      }
      const $this = $(this);
      const $parent = $this.parent();
      //success! Continue
      addQuantityByProductCollectionMemberId(parseInt($this.prop("href").split("#")[1].replace(/\D/g, ""), 10), 1);
      $parent.effect("transfer", { to: ".menuCart" }, animationProps.duration, () => {
        $parent.remove();
      });
      $(".ui-effects-transfer").css({
        background: `#FFF url(${$this.find("img").prop("src")}) no-repeat center top`,
        "-webkit-background-size": "contain",
        "-moz-background-size": "contain",
        "-o-background-size": "contain",
        "background-size": "contain",
      });
      return false;
    });

    //always scroll straight to the product for mobile visitors
    if (utils.isMobileVisit()) {
      $("html, body").scrollTop($(".crumbPath").offset().top);
    }

    $(".finishOrder").click(() => {
      window.location = "/cart";
      return false;
    });
  });
}

function route() {
  if (!$webProductDetails || $webProductDetails.length === 0) {
    return;
  }

  //Restore the menu to it's original state
  if (originalMenu) {
    $navigation.removeClass("collapsed");
    $productCategories.html(originalMenu);
  }

  if ($extraProductsFooter) {
    $(
      ".productPreview, .preSubmit, " +
        ".webProductDetails .leftColumn, " +
        ".webProductDetails .rightColumn, " +
        ".webProductDetails h1, " +
        ".webProductDetails .orderButton"
    ).attr("style", "");
    $(".webProductDetails .postSubmit, .buttonContainer").hide();
    $(".webProductDetailsFooter").show();
    $webProductDetails.removeClass("collapsed");
    $extraProductsFooter.hide();
  }
}

export default {
  init: init,
  route: route,
};
