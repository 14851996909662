import $ from "jquery";
import { addViewPortListener } from "../global/utils";
import { drawStars } from "../global/utils/stars";
import CoverModal from "./CoverModal";

addViewPortListener(
  () => {
    $("iframe").each(function () {
      const $el = $(this);

      // not a youtube embed
      if (!/youtube\.com\/embed\//gim.test($el.attr("src"))) {
        return;
      }

      // already wrapped by controller content orso
      if ($el.parent().hasClass("videoWrapper")) {
        return;
      }

      $el.addClass("is-wrapped-youtube-embed").wrap('<div class="youtube-embed"/>').wrap('<div class="videoWrapper"/>');
    });
  },
  () => {
    $("iframe").each(function () {
      const $el = $(this);

      // not a youtube embed
      if (!/youtube\.com\/embed\//gim.test($el.attr("src"))) {
        return;
      }

      if (!$el.hasClass("is-wrapped-youtube-embed")) {
        // not wrapped by mobile listener (above)
        return;
      }

      $el
        .removeClass("is-wrapped-youtube-embed")
        .unwrap('<div class="youtube-embed"/>')
        .unwrap('<div class="videoWrapper"/>');
    });
  }
);

// http://stackoverflow.com/questions/6443990/javascript-calculate-brighter-colour
function increaseBrightness(hex, percent) {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, "");

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, "$1$1");
  }

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return `#${(0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1)}${(
    0 |
    ((1 << 8) + g + ((256 - g) * percent) / 100)
  )
    .toString(16)
    .substr(1)}${(0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)}`;
}

function applyColorEffects() {
  const $el = $(this);
  const color = $el.data("color");

  if (color) {
    $el.css({
      "background-color": `#${color}`,
      "background-image": `linear-gradient(75deg,#${color}, ${increaseBrightness(String(color), 80)})`,
    });
  }
}

export function init() {
  $("a.wua").click(function () {
    window.open(
      this.href,
      "WUA",
      "resizable=yes,status=yes,location=no,toolbar=no,menubar=no,fullscreen=no,scrollbars=yes,dependent=no"
    );
    return false;
  });

  //reviews
  $(".reviews li").each(function () {
    const $this = $(this);
    const score = parseInt($this.prop("className").replace(/\D/g, ""), 10) / 2;
    drawStars($this, score);
    const $reviewBody = $this.find(".reviewBody");
    if ($reviewBody.readmore) {
      $reviewBody.readmore({
        maxHeight: 80,
        heightMargin: 32,
        moreLink: '<a href="#">Lees meer</a>',
        lessLink: '<a href="#">Minder</a>',
      });
    }
  });

  $(".productCategoriesBar > ol").each(function () {
    let rowPointer = 0;
    $(this)
      .children()
      .each(function () {
        const $li = $(this);

        applyColorEffects.bind(this).call();

        rowPointer += parseInt($li.attr("class").replace(/\D/g, ""), 10);
        if (rowPointer === 12) {
          $li.css("margin-right", "0");
          rowPointer = 0;
        }
      });
  });

  $(".cardBar > a").each(applyColorEffects);

  CoverModal();
}
