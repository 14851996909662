import debounce from "lodash/debounce";

import $ from "jquery";
import * as utils from "../utils";
import * as catalog from "../../pages/catalog";
import catalogIndex from "../../pages/catalog/index";
function initBehaviour() {
  // add time dependent titles to the categories
  $.get("/gateway/boeketcadeau2014/catalog/?localAction=clocks").then((nodes) => {
    $.each(nodes, (idx, node) => {
      const $el = $(`.categoryHoverOuter.mho_wpc_${node.id}`).find(".line2");
      if (node.clockInfo.line1 || node.clockInfo.title2) {
        $el.html(node.clockInfo.line1 || node.clockInfo.title2);
      }
    });
  });

  const isPartnerSite = utils.isPartnerSite();

  // some jQuery caching
  const $menu = $("#productCategoryNavigation");
  const $showMoreContainerHover = $(".showMoreContainerHover");
  const $showMoreContainerHoverNavigation = $showMoreContainerHover.find("#showMoreContainerHoverNavigation");
  const $showMoreContainerHoverInner = $showMoreContainerHover.find(".showMoreContainerHoverInner .categoryHoverInner");
  const $showMoreMenu = $menu.find("li.showMoreContainer");
  const $offerHeader = $("body > .offerBar");

  let $menuLis = $menu.find("li");
  let newNavigationWidth = $showMoreMenu.width();

  if (!isPartnerSite) {
    $menuLis = $menuLis.add($showMoreContainerHoverNavigation.find("li"));
    newNavigationWidth *= 2;
  }

  $showMoreContainerHoverNavigation.width(newNavigationWidth);
  $showMoreContainerHoverInner
    .find(".internal-wrapper")
    .outerWidth($showMoreContainerHoverInner.width() - newNavigationWidth);

  if ($offerHeader.length) {
    $(".categoryHoverOuter")
      .add("#showMoreContainerHoverNavigation")
      .each((_, element) => {
        const $element = $(element);
        let minHeight = $offerHeader.outerHeight();

        if ($element.parent(".navigation").length) {
          minHeight += parseInt($element.parent(".navigation").css("margin-bottom"));
        }

        $element.css("min-height", minHeight);
      });
  }

  /**
   * Deactivate dropdown on mouseleave
   *
   * @type {*|jQuery}
   */
  const $catHover = $(".navigation .categoryHoverOuter,.showMoreContainerHover").on("mouseleave", (e) => {
    if (utils.isMobileVisit() || $(e.currentTarget).data("open")) {
      return;
    }
    $(".categoryHoverOuter,.showMoreContainerHover").removeClass("active");
    $menuLis.removeClass("hover");
  });

  /**
   * Hide the dropdown when mouseleave the menu in another direction (i.e. to the top) and not entering the
   * dropdown
   */
  $menu.on("mouseleave", (e) => {
    if (utils.isMobileVisit() || $(e.currentTarget).data("open")) {
      return;
    }
    isWaiting = false;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const $whereTo = $(e.relatedTarget);

    if (!($whereTo.hasClass("categoryHoverOuter") || $whereTo.hasClass("showMoreContainerHover"))) {
      $(".categoryHoverOuter,.showMoreContainerHover").removeClass("active");
      $menuLis.removeClass("hover");
    }
  });

  let h1 = $showMoreContainerHoverNavigation.outerHeight();

  /**
   * Make the shoreMore navigation bar the same height as the categoryHoverOuter height
   *
   * @param {jQuery} $categoryHoverOuter
   */
  function fixShowMoreNavigationVsContainerHeight($categoryHoverOuter) {
    if (!h1) {
      h1 = $("#showMoreContainerHoverNavigation").css("min-height", "").height();
    }
    const h2 = $categoryHoverOuter.outerHeight();

    if (h1 >= h2) {
      $categoryHoverOuter.css("min-height", `${h1}px`);
    }
    $showMoreContainerHoverNavigation.css("min-height", `${h1 < h2 ? h2 : h1}px`);
  }

  let isWaiting = false;
  function onTouchyFeely(e) {
    const $current = $(e.currentTarget);
    if ($current.data("open")) {
      $current.data("open", false);
      e.stopPropagation();
      return;
    }

    let addTouchCloseButton = false;

    $menuLis.removeClass("hover").data("open", false);
    if (e.type === "touchstart" && !$current.hasClass("top")) {
      e.preventDefault();
      e.stopPropagation();
      addTouchCloseButton = true;
      $current.data("open", true);
    }

    $catHover.removeClass("active");
    $menuLis.removeClass("hover");
    $showMoreContainerHover.find(".categoryHoverOuter.active").removeClass("active");

    const $li = $(this).addClass("hover");

    let $categoryHoverOuter = [];

    if ($li.prop("id")) {
      $categoryHoverOuter = $(`.categoryHoverOuter.mho_${$li.prop("id").replace("mho_", "")}`);
    }

    if (!$categoryHoverOuter.length) {
      $categoryHoverOuter = $(".categoryHoverOuter.empty");
    }

    $categoryHoverOuter.addClass("active").addClass(addTouchCloseButton ? "touched" : "");

    if ($li.hasClass("showMoreContainer")) {
      $showMoreContainerHover.addClass("active").addClass(addTouchCloseButton ? "touched" : "");

      let $categoryHoverOuter = $showMoreContainerHover.find(".categoryHoverOuter").first();

      const $selectedLi = $showMoreContainerHoverNavigation.find("li.selected");
      if ($selectedLi.length === 1) {
        $categoryHoverOuter = $showMoreContainerHover.find(`.categoryHoverOuter.${$selectedLi.prop("id")}`);
      } else if (isPartnerSite) {
        $showMoreContainerHoverNavigation.find("li").first().addClass("selected");
      }

      fixShowMoreNavigationVsContainerHeight($categoryHoverOuter.addClass("active"));
    }
  }

  let timeoutId = 0;
  /**
   * Entering/touching a main menu item should enable dropdown.
   * If touched then does not close the dropdown on mouseleave but adds a "close button".
   */
  $menu.find("> li").on("mouseenter touchstart", function (e) {
    if (utils.isMobileVisit()) {
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    isWaiting = true;
    timeoutId = setTimeout(() => {
      if (isWaiting) {
        isWaiting = false;
        onTouchyFeely.bind(this)(e);
      }
    }, 250);
  });

  /**
   * The showMore navigation should activate the categoryHoverOuter and resize the container
   */
  const $moreMenuLis = $showMoreContainerHoverNavigation.find("> li");
  $moreMenuLis.on("mouseenter touchstart", function touchyFeely(e) {
    if (utils.isMobileVisit()) {
      return;
    }

    const $current = $(e.currentTarget);
    if ($current.data("open")) {
      $current.data("open", false);
      e.stopPropagation();
      return;
    }

    $moreMenuLis.removeClass("hover").data("open", false);
    if (e.type === "touchstart" && !$(e.currentTarget).hasClass("top")) {
      e.preventDefault();
      e.stopPropagation();
      $current.data("open", true);
    }

    $showMoreContainerHover.find(".categoryHoverOuter").removeClass("active");
    $showMoreContainerHover.find("li").removeClass("hover");

    const $li = $(this);
    let $categoryHoverOuter = [];
    $li.addClass("hover");

    // Get the categoryHoverOuter of the current li
    if ($li.prop("id")) {
      $categoryHoverOuter = $(`.categoryHoverOuter.${$li.prop("id")}`);
    }

    // If the li doens't have a categoryHoverOuter show the empty one
    if (!$categoryHoverOuter.length) {
      $categoryHoverOuter = $(".categoryHoverOuter.empty");
    }

    $categoryHoverOuter.addClass("active");
    fixShowMoreNavigationVsContainerHeight($categoryHoverOuter);
  });

  /**
   * touched close button
   */
  $(".categoryHoverOuter,.showMoreContainerHover").on("click", ".closeButton", () => {
    $(".categoryHoverOuter,.showMoreContainerHover").removeClass("active");
    $menuLis.removeClass("hover");
  });

  /**
   * touching outside of the menu should close it
   */
  $(document).on("touchstart", (e) => {
    if (utils.isMobileVisit() || $(e.target).parent().parent().data("open")) {
      return;
    }
    if ($(e.target).parents(".categoryHoverOuter,.showMoreContainerHover").length > 0) {
      return;
    }
    $(".categoryHoverOuter,.showMoreContainerHover").removeClass("active");
    $menuLis.removeClass("hover");
  });

  /**
   * The showMore offerBar must not pass the showMore container navigation bar
   */
  const $showMoreContainerOfferBars = $showMoreContainerHover.find(".offerBar");

  // let offerBgImageWidth = 1200; // NOTE this could possibly be calculated
  function fixOfferBar() {
    // fix max-width of offerBar
    const offset = $showMoreContainerHoverNavigation.offset();
    if (!offset) {
      return;
    }
    const posX = Math.round(offset.left + $showMoreContainerHoverNavigation.width());
    $showMoreContainerOfferBars.css("max-width", `${posX}px`);

    if ($showMoreContainerOfferBars.length) {
      $showMoreContainerOfferBars.css(
        "background-position",
        `${
          Math.round($showMoreContainerOfferBars.find(".offer").css("margin-left").replace("px", "")) -
          $showMoreContainerHoverNavigation.width() * 2
        }px -82px`
      );
    }
  }

  $(window).on("resize", debounce(fixOfferBar, 56));
  fixOfferBar();
}

export default {
  init() {
    // this is run after document ready
    if (utils.isMobileVisit()) {
      return;
    }

    const $menuEl = $("#hoverMenu");
    $.get(`/gateway/boeketcadeau2014/catalog?localAction=Menu&webProductCollectionId=${$menuEl.data("id")}`).then(
      (result) => {
        $menuEl.replaceWith(result);

        catalog.initProductBars();
        catalogIndex.initOfferBars();

        initBehaviour();

        $("header.navigation").find("img[data-src],[data-background-image-url],iframe[data-src]").lazyLoad();
      }
    );
  },
};
