import $ from "jquery";

const init = function () {
  const $form = $(".loginBar form");
  $form.submit(() => {
    $.post("/gateway/login", $form.serialize(), (result) => {
      if (result && result.message) {
        window.alert(result.message);
        return;
      }
      window.location = "/profiel";
    });
    return false;
  });
};

export default { init: init };
