// tslint:disable-next-line:no-submodule-imports
import find from "lodash/find";

import { TCartModel } from "~/model/Cart";
import getContext, { IAvailableCard } from "./getContext";

export default function (cart?: TCartModel): JQueryPromise<IAvailableCard | null> {
  if (!cart) {
    return $.when(null);
  }
  return getContext(cart).then((cartContext) => {
    let cardContext;
    const cardType = cart.get("card").get("type");
    if (cartContext && cardType) {
      cardContext = find(cartContext.availableCards, (card) => card.id === cardType);
    }
    return cardContext || null;
  });
}
