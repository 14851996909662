// @ts-ignore
import BackboneSchema from "backbone-json-schema";

export default BackboneSchema.Model.extend({
  toJSON() {
    // eslint-disable-next-line prefer-rest-params
    const data = BackboneSchema.Model.prototype.toJSON.apply(this, arguments);
    if (data && data.type && data.type !== 1) {
      delete data.afterpayment;
    }
    return data;
  },
});
