import $ from "jquery";

const page = $("html, body");

export default {
  scrollTo(top, duration) {
    page.on("scroll mousedown wheel DOMMouseScroll mousewheel touchmove", () => {
      page.stop();
    });
    page.stop(true, true).animate(
      {
        scrollTop: top,
      },
      duration
    );
  },
};
