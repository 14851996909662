import Backbone from "backbone";
import $ from "jquery";
import Handlebars, { getTemplate } from "../../global/renderEngine";

const templateSource = getTemplate("profile/changePassword");

export default Backbone.View.extend({
  events: {
    'click input[type="submit"]': "doPasswordChange",
    "click button": "doGenerate",
    "change #displayPassword": "doPasswordDisplay",
    'keyup input:not([type="submit"])': "doValidate",
  },

  template: Handlebars.compile(templateSource),

  render: function () {
    this.$el.html(this.template({}));
    return this;
  },

  doPasswordChange: function () {
    const me = this;
    const $button = this.$el.find(".submit");

    $.post(
      "/gateway/login/?localAction=changePassword",
      {
        password1: this.$el.find("#password1").val(),
        password2: this.$el.find("#password2").val(),
      },
      (result) => {
        me.$el.html(me.template({ success: result, failed: !result }));
        $button.prop("disabled", "");
      }
    );
    $button.prop("disabled", "disabled");
    return false;
  },

  doPasswordDisplay: function (event) {
    this.$el
      .find("input#password1,input#password2")
      .attr("type", $(event.currentTarget).is(":checked") ? "text" : "password");
    return false;
  },

  doValidate: function (event) {
    const $current = $(event.currentTarget);

    const $block = $current.parents(".changePassword");

    const $submit = $block.find('[type="submit"]').prop("disabled", "disabled");

    ///////////////////////////////////////////////////////
    // check value conforms to requirements
    //
    if (!this.isValidPassword($current.val())) {
      $current.addClass("error");
      return;
    } else {
      $current.removeClass("error");
    }

    ///////////////////////////////////////////////////////
    // check first value is ok against second value
    //
    if ($current.attr("id") === "password1" && $current.val() !== $block.find("#password2").val()) {
      $submit.prop("disabled", "disabled");
      return;
    }

    ///////////////////////////////////////////////////////
    // check second value is ok against first value
    //
    if ($current.attr("id") === "password2" && $current.val() !== $block.find("#password1").val()) {
      $current.addClass("error");
      $submit.prop("disabled", "disabled");
      return;
    }

    ///////////////////////////////////////////////////////
    // enable submit button
    //
    $current.removeClass("error");
    $submit.prop("disabled", "");
  },

  /**
   * Checks the password is "simply" valid..currently:
   *
   *  longer than 5
   *
   * @param value
   * @returns {boolean}
   */
  isValidPassword: function (value) {
    return value.length >= 5;
  },
});
