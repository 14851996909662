import * as Backbone from "backbone";
import $ from "jquery";
// tslint:disable-next-line:no-submodule-imports
import debounce from "lodash/debounce";

import immutable, { ImmutableObject } from "seamless-immutable";
import { ICartContext } from "~/global/cart/getContext";
import globalSchema from "~/global/schema";
import { TCartModel } from "~/model/Cart";
import { Cart } from "~/types/kmt";
import globalCart from "../global/cart";
import Handlebars, { getTemplate } from "../global/renderEngine";
import * as utils from "../global/utils";
import CartItemsView from "../view/CartItems";

const templateSource = getTemplate("menu/cart");

export default Backbone.View.extend({
  events: {
    "click >a": "toggleCart",
  },

  itemsView: null,

  template: Handlebars.compile(templateSource),

  isInCart: $("body").find(".cartIndex").length === 1,
  isEditOrder: window.location.href.indexOf("wijzig-order") >= 0,

  initialize() {
    // @todo find a good way to prevent code dup view/layout/js/boeketcadeau2014/view/cart/Summary.js
    this.model.get("card").on("change:type change:quantity change:chocolateBarQuantity", this.render.bind(this));
    this.model.get("address").get("recipient").on("change:countryCode change:zipcode", this.render.bind(this));
    this.model.get("items").on("add remove change:quantity change:customPrice", this.render.bind(this));
    this.model.get("delivery").on("change", this.render.bind(this));
    this.model.get("final").on("change:postalInvoice", this.render.bind(this));
  },

  render() {
    $.when<any>(globalCart.getContext(), globalCart.get(), globalSchema.getCartPromise()).then(
      (context: ICartContext, cart: TCartModel, cartSchema) => {
        if (this.itemsView) {
          // unbind events?
          this.itemsView.undelegateEvents();
        }
        const isFilled = this.isFilled();

        if (isFilled) {
          this.$el.css("display", "block");
          this.$el.parents(".topNavigation").addClass("open");
        }

        this.$el.html(
          this.template({
            cartLinkClass: isFilled ? "filled" : "",
            totalCount: context.totalCount,
          })
        );

        this.itemsView = new CartItemsView({
          model: this.model,
          el: this.$(".orderItems")[0],
        });

        let immutableContext = immutable(context).set("cart", cart.toJSON()) as ImmutableObject<
          ICartContext & { cart: Cart }
        >;

        if (
          immutableContext.availableExtraServicePhoto &&
          immutableContext.cart.extraService &&
          immutableContext.cart.extraService.photo === 1
        ) {
          immutableContext = immutableContext.set(
            "extraServicePhotoPrice",
            cartSchema.properties.extraService.properties.photo.enumPrices[0]
          );
        }

        if (!immutableContext.cart.address.recipient.zipcode || !immutableContext.cart.address.recipient.streetNumber) {
          if (immutableContext.shippingCosts) {
            immutableContext = immutableContext
              .set("total", immutableContext.total - immutableContext.shippingCosts)
              .set("shippingCosts", false);
          }
          if (immutableContext.timeFramePrice) {
            immutableContext = immutableContext
              .set("total", immutableContext.total - immutableContext.timeFramePrice)
              .set("timeFramePrice", false);
          }
        }
        this.itemsView.render(immutableContext);

        if (isFilled) {
          this.makeSticky();
        }

        if (this.isInCart) {
          // #6.34
          this.$(".finaliseOrderButton").hide();
        }

        if (this.isEditOrder) {
          this.$(":input").prop("disabled", true);
          this.$(".delete a").hide();
        }
      }
    );

    return this;
  },

  getRightPosition(): number {
    const $navBar = $(".footerMenu");
    const offset = $navBar.offset();
    if (!offset) {
      return 0;
    }
    return (
      $(window).width() - (offset.left + $navBar.width()) - parseInt($navBar.css("padding-left").replace("px", ""), 10)
    );
  },

  makeSticky() {
    const isInDetails = $("body").find(".webProductDetails").length === 1;

    // on mobile or in globalCart or in details then make it floaty
    if (utils.isMobileVisit() || this.isInCart || isInDetails) {
      this.$el.addClass("floaty");
      return;
    }

    if (!this.$el.sticky) {
      return;
    }

    // else make it sticky (fixed)
    this.$el.sticky({ zIndex: 9980085 });
    this.$el.on("sticky-start", this.align.bind(this));
    this.$el.on("sticky-end", this.align.bind(this));
    this.$el.sticky("update");

    const $window = $(window);
    const $parent = this.$el.parent();

    // Fix for reposition on resize
    $window.on(
      "resize",
      debounce(() => {
        this.$el.css("width", "");
        const isSticky = $parent.hasClass("is-sticky");
        if (!isSticky) {
          return;
        }
        this.$el.css("right", this.getRightPosition());
      }, 5)
    );
  },

  isFilled() {
    return this.model.get("items").length > 0 || this.model.get("card").get("type");
  },

  toggleCart(event: Event) {
    this.$el.css("display", this.isFilled() ? "block" : "none");
    this.$el.parents(".topNavigation").toggleClass("open", this.isFilled());

    this.$el.toggleClass("selected");
    this.align(event);
    return false;
  },

  align(event: Event) {
    if (utils.isMobileVisit()) {
      return;
    }

    let isSticky;
    switch (event.type) {
      case "sticky-start":
        isSticky = true;
        break;

      case "sticky-end":
        isSticky = false;
        break;

      default:
        isSticky = this.$el.parents(".is-sticky").length > 0;
    }

    this.$el.css("width", "");
    this.$el.css("right", isSticky ? this.getRightPosition() : "");
  },
});
