import $ from "jquery";
import catalogUtils from "../pages/catalog/utils";
import catalogIndex from "../pages/catalog/index";
import catalogDetails from "../pages/catalog/details";
import { drawStars } from "../global/utils/stars";
import { isPartnerSite } from "../global/utils";

function onQuantityChange() {
  const quantity = parseInt($(this).val(), 10);
  if (quantity > 0) {
    const $li = $(this).parents("li");
    const pricePerProduct = parseFloat($(this).data("price-per-product"));
    // both the image _AND_ the price link should use the quantity
    // https://trello.com/c/kwn9OoNh/110-mouse-over-product#comment-57b4cf8f9e53dd6df1eb6169
    const $orderButtons = $li.find(".orderLink, .orderButton");
    const hrefStart = $orderButtons.prop("href").split("#").shift();
    $li.find(".price").text(catalogUtils.price(quantity * pricePerProduct, "€ "));
    $orderButtons.prop("href", `${hrefStart}#${quantity}`);
  }
}

export function initProductBars() {
  //product bars are used in catalog index, catalog details (extra products) and search results
  $(".productsBar > ol > li").each(function () {
    const $li = $(this);
    const $rating = $(".averageRating", $li);
    const $alsoAvailable = $(".alsoAvailable", $li);
    const $freeQuantityEntry = $(".freeQuantityEntry", $li);
    const color = $li.data("color");

    if (color) {
      let $els = $(".orderButton, .show360Button", $li);
      if (!isPartnerSite()) {
        $els = $els.add($alsoAvailable).add($freeQuantityEntry);
      }
      $els.css({
        "background-color": `#${color}`,
      });
    }

    if (!$(".background", $li).length) {
      // The hover background of the li
      const $background = $("<div>");

      // height = li.height + alsoAvailable.height + padding top/bottom
      $background.addClass("background").css({
        height: `${
          $li.outerHeight() +
          ($alsoAvailable.length ? $alsoAvailable.outerHeight() : $freeQuantityEntry.outerHeight()) +
          20
        }px`,
      });

      $li.append($background);
    }

    drawStars($rating, parseInt($rating.data("value"), 10) / 2);
  });
}

export function init() {
  $("body")
    .on("change", ".productsBar input", onQuantityChange)
    .on("click", ".productsBar button", function () {
      const $this = $(this);
      const $input = $this.parents(".freeQuantityEntry").find("input");
      const minQuantity = parseInt($input.prop("min"), 10);

      let currentQuantity = parseInt($input.val(), 10);
      if (currentQuantity < minQuantity) {
        currentQuantity = minQuantity;
      }
      let newQuantity = currentQuantity + ($this.parent().is(".plusButtonContainer") ? 1 : -1);
      if (newQuantity <= minQuantity) {
        newQuantity = minQuantity;
      }

      $input.val(newQuantity);
      onQuantityChange.apply($input[0]);
    });

  initProductBars();

  //WebProductCollection page
  if ($(".productsBar").length > 0) {
    catalogIndex.init();
  }

  if ($(".webProductDetails").length > 0) {
    catalogDetails.init();
  }
}

let itemsCount = 0;
const ElementsThatShouldBeMoved = [];
// Get items that should be re-ordered
$("body > .productsBar > ol > li, body > .extraBar > .productsBar > ol > li").each(function () {
  const $li = $(this);
  const isBigger = $li.hasClass("width-6") || $li.hasClass("width-12");
  // li.width-6 and li.width-12 are the same size as two regular li's on mobile
  itemsCount = itemsCount + (isBigger ? 2 : 1);
  if (!isBigger || itemsCount % 2 === 0) {
    return;
  }
  ElementsThatShouldBeMoved.push($li);
});

// Only if there are elements that should be moved
if (ElementsThatShouldBeMoved.length > 0) {
  const $window = $(window);
  const breakPoint = 800;
  let lastScreenWidth = $window.width();

  // Re-order on load, for mobile users only
  $window.on("load", () => {
    const currentScreenWidth = $window.width();
    if (currentScreenWidth < breakPoint) {
      moveElements(ElementsThatShouldBeMoved, true);
    }
  });

  // Re-order if needed
  $window.on("resize", () => {
    const currentScreenWidth = $window.width();
    if (currentScreenWidth > breakPoint && lastScreenWidth < breakPoint) {
      moveElements(ElementsThatShouldBeMoved, false);
      lastScreenWidth = currentScreenWidth;
    } else if (currentScreenWidth < breakPoint && lastScreenWidth > breakPoint) {
      moveElements(ElementsThatShouldBeMoved, true);
      lastScreenWidth = currentScreenWidth;
    }
  });
}

// The actual re-ordering
function moveElements($elements, moveUp) {
  $($elements).each(function () {
    const $element = $(this);
    if (moveUp) {
      $element.prev().insertAfter($element);
      return;
    }
    $element.next().insertBefore($element);
  });
}

export const routeDetails = catalogDetails.route;
