// @ts-ignore
import BackboneSchema from "backbone-json-schema";

export default BackboneSchema.Model.extend({
  toJSON() {
    // eslint-disable-next-line prefer-rest-params
    const data = BackboneSchema.Model.prototype.toJSON.apply(this, arguments);
    if (data && data.type === "person") {
      delete data.companyDetails;
    }

    if (
      data &&
      data.companyDetails &&
      data.companyDetails.invoicePeriod &&
      data.companyDetails.invoicePeriod === "perMonth"
    ) {
      delete data.preferredPaymentMethod;
    }

    return data;
  },
});
