import $ from "jquery";
import { JSONSchema4 } from "json-schema";
import { TCartModel } from "../../model/Cart";
import { TypeBezorgAdres, UBent } from "../../types/kmt";
import modelFactory from "../modelFactory";
import getCardContext from "./getCardContext";
import getContext from "./getContext";
import cartStore from "./store";

export default function setupCart(): JQueryPromise<TCartModel> {
  return $.when(modelFactory.create("Cart"), JSON.parse(cartStore.getData())).then((CartModel, data) => {
    const cart = new CartModel();
    if (!CartModel.schema.properties.createDateTime) {
      CartModel.schema.properties.createDateTime = {
        type: "string",
      };
    }
    cart.set(data);

    // install change handlers _everywhere_ to persist the cart - all the way down for nested models/collections
    function installPersistHandlers(object: Backbone.Model, schema: JSONSchema4) {
      const { properties = {} } = schema;
      object.on("change add remove", () => {
        // cart.unset('pristine', {silent: true, force: true});
        cartStore.store(cart);
      });
      Object.keys(properties).forEach((propertyName) => {
        const propertySchema = properties[propertyName];
        const propertyValue = object.get(propertyName);
        if (propertyValue && (propertyValue.attributes || propertyValue.models)) {
          installPersistHandlers(propertyValue, propertySchema);
        }
      });
    }

    if (
      $("body").data("is-logged-in") &&
      cart.get("address").get("customer").get("companyDetails") &&
      cart.get("address").get("customer").get("companyDetails").get("invoicePeriod") === "perMonth"
    ) {
      cart.get("payment").set("type", 943243);
    }

    // KMT-116662
    getContext(cart).then((ctx) => {
      cart
        .get("address")
        .get("recipient")
        .on("change:addressType", (recipient: Backbone.Model, newValue: TypeBezorgAdres) => {
          const is9ToFiveCompany = ["company", "hospital", "nursing home"].indexOf(newValue) !== -1;

          const currentTimeframeId = cart.get("delivery").get("timeFrame");

          // if switched to a "company" delivery addressType then we set the timeFrame to the company all day
          if (ctx && currentTimeframeId === ctx.constants.TIMEFRAME_ID_ALLDAY && is9ToFiveCompany) {
            // All Day 9:00 - 18:00
            cart.get("delivery").set("timeFrame", ctx.constants.TIMEFRAME_ID_COMPANY); // All Day 9:00 to 17:00
          }

          // if switched back to a "normal" delivery addressType then we set the timeFrame back to allDay
          if (ctx && currentTimeframeId === ctx.constants.TIMEFRAME_ID_COMPANY && !is9ToFiveCompany) {
            // All Day 9:00 to 17:00
            cart.get("delivery").set("timeFrame", ctx.constants.TIMEFRAME_ID_ALLDAY); // All Day 9:00 to 18:00
          }
        });
    });

    cart
      .get("address")
      .get("customer")
      .on("change:type", (customer: Backbone.Model, newValue: UBent) => {
        if (newValue === "person") {
          cart.get("final").set("postalInvoice", false);
        }
      });

    const deliveryDate = cart.get("delivery").get("date");

    const isEdit = window.location.href.indexOf("wijzig-order");

    if (!isEdit && deliveryDate) {
      // on page reload and the date s no longer available; then remove it
      // also make sure any other listeners are not notified!
      getContext(cart).then((ctx) => {
        if (ctx && ctx.availableDates.indexOf(deliveryDate) === -1) {
          cart.get("delivery").unset("date", { silent: true });
        }
      });
    }

    cart.get("card").on("change:type", (card: Backbone.Model) => {
      // set the "text" property to a proper value...
      getCardContext(cart).then((cardContext) => {
        const typeofText = typeof card.get("text");

        if (!cardContext) {
          return;
        }

        switch (cardContext.type) {
          case 0: // Card
          case 2: // White card
            if (typeofText !== "string") {
              card.set("text", "");
            }
            break;

          case 1: // Ribbon
            if (typeofText !== "object") {
              card.set("text", {
                left: "",
                right: "",
              });
            }
            break;

          // case 3: //No card
          default:
            if (typeofText !== "undefined") {
              card.set("text", undefined);
              break;
            }
        }
      });
    });

    cart
      .get("address")
      .get("customer")
      .on("change:anonymous", (card: Backbone.Model, newValue: boolean) => {
        getContext(cart).then((cartContext) => {
          if (newValue && !card.get("type")) {
            if (cartContext && cartContext.availableDeliveryCountries.length > 5) {
              // 20812229 Geen kaartje (Buitenland)
              card.set("type", 20812229);
              return;
            }

            if (cartContext && cartContext.isMourning) {
              // 20805180 Geen kaartje of lint
              card.set("type", 20805180);
              return;
            }

            // 20805179 Geen kaartje
            card.set("type", 20805179);
          }
        });
      });

    const payment = cart.get("payment");
    // E.g. Philips has no payment
    if (payment) {
      payment.on("change:type", (model: Backbone.Model, newValue: number) => {
        // 1: after pay
        const currentAfterPayValue = model.get("afterPayment");
        if (newValue === 1 && !currentAfterPayValue) {
          // make sure all appropriate properties can be validated
          model.set("afterPayment", -1);
        }

        if (newValue !== 1 && currentAfterPayValue) {
          model.set("afterPayment", 0);
        }
      });
    }

    cart.get("items").on("add", () => {
      if (cart.get("items").length === 1) {
        const $menuCart = $(".menuCart");
        $menuCart.css("display", "block");
        $menuCart.parents(".topNavigation").addClass("open");
      }
    });

    // install persist handlers here so it is not fire for all the above changes to the cart
    installPersistHandlers(cart, CartModel.schema);

    // cart.set('pristine', true, {silent: true, force: true});
    cart.pristine = true;
    return cart;
  });
}
