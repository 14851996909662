import $ from "jquery";
import vent from "~/global/vent";

const cache = {};
$.ajaxSetup({ cache: false });

export function getCachedXhrPromise(method, url, params, settings) {
  params = params || {};
  settings = settings || {};

  settings.method = method;
  settings.timeout = 5000;

  if (method === "post") {
    settings.data = params || {};
  }

  if (method === "get") {
    url += (/\?/.test(url) ? "&" : "?") + $.param(params);
  }

  // eslint-disable-next-line prefer-rest-params
  const hash = JSON.stringify($.makeArray(arguments));

  let hasCache = typeof cache[hash] !== "undefined";

  if (settings.force && hasCache) {
    if (cache[hash].abort) {
      cache[hash].abort();
    }
    cache[hash] = undefined;
    hasCache = false;
  }

  if (!hasCache) {
    cache[hash] = $.ajax(url, settings).then((value) => {
      vent.trigger("context.update", value);
      return value;
    });
  }

  return cache[hash];
}

// todo some way to centralize this ;-)
export const responsive = {
  xs: 320,
  sm: 420,
  md: 736,
  lg: 1024, // 50em?
};

export function isMobileVisit(type, comparison) {
  if (!type || !window.matchMedia || !this.responsive[type]) {
    return !$(".pages").is(":visible");
  }

  // // console.log(type, comparison);
  const keys = Object.keys(this.responsive);
  // // console.log(keys);
  let keyIndex = -1;
  keys.forEach((value, idx) => {
    if (value === type) {
      keyIndex = idx;
    }
  });

  if (comparison === "lt") {
    return window.matchMedia(`(max-width: ${this.responsive[keys[keyIndex]] - 1}px)`).matches;
  }

  // // console.log(keyIndex);
  // inverse faking it ;-)
  return window.matchMedia(`(min-width: ${this.responsive[keys[keyIndex]]}px)`).matches;
}

export function addViewPortListener(mobileCb, desktopCb) {
  function apply(mql) {
    if (!mql.matches && mobileCb) {
      /* The viewport is currently in "mobile view" */
      mobileCb();
    } else if (desktopCb) {
      /* The viewport is currently in "desktop view" */
      desktopCb();
    }
  }

  // oldie fallback
  if (!window.matchMedia) {
    apply({ matches: !this.isMobileVisit() });
    return;
  }

  // mql for desktop view
  const mql = window.matchMedia("(min-width: 50em)");

  // we might already be in mobile view..
  apply(mql);

  // we might orientation change the device...
  if (mql.addListener) {
    mql.addListener(apply);
  }
}

export function isOldIe() {
  if (typeof this._isOldIe === "undefined") {
    this._isOldIe = $("html").hasClass("oldie");
  }
  return this._isOldIe;
}

export function price(number) {
  if (!number || isNaN(number)) {
    number = 0;
  }
  return `€ ${number.toFixed(2).replace(/\./g, ",")}`;
}

export const isPartnerSite = () => !document.getElementById("20654669");
