import * as Backbone from "backbone";
import $ from "jquery";
import DeliveryAddressFormFields from "./DeliveryAddressFormFields";
import Handlebars, { getTemplate } from "../global/renderEngine";
import formValidator from "../global/form/validator";
import formParser from "../global/form/parser";
import BaseView from "./Base";

const templateSource = getTemplate("form");

export default BaseView.extend({
  events: {
    "submit form": "submit",
  },

  template: Handlebars.compile(templateSource),

  render() {
    this.$el.html(
      this.template({
        class: "deliveryAddress",
        action: "#",
        formFields: "",
      })
    );
    const view = new DeliveryAddressFormFields({
      el: this.$(".fieldset")[0],
      model: this.model,
      path: [],
    });
    view.render();
    return this;
  },

  submit() {
    const self = this;
    formValidator.getEnvironment().then((env) => {
      const deliveryAddress = formParser.parseForm(self.$el);
      const errors = env.validate("deliveryAddress", deliveryAddress, formValidator.jjvOptions);
      if (errors && errors.validation) {
        self.$el.find("input[name], textarea[name], select[name]").each(function () {
          formValidator.toggleErrorClass($(this).prop("name"), errors.validation);
        });
        formValidator.scrollToFirstError();
        return;
      }

      const postData = { data: JSON.stringify(deliveryAddress) };
      const addressDetails = Backbone.history.getHash().split("/");
      if (addressDetails[2]) {
        postData.id = parseInt(addressDetails[2], 10);
      }
      $.post("/gateway/addressBook?localAction=update", postData, (response) => {
        if (response.success) {
          Backbone.history.navigate("addressBook", { trigger: true });
          return;
        }
        window.alert("Er gaat iets mis. Controleer uw invoer");
      });
    });
    return false;
  },
});
