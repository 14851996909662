import BaseView from "./Base";
import $ from "jquery";
import CustomerFormFields from "./CustomerFormFields";
import Handlebars, { getTemplate } from "../global/renderEngine";
import formValidator from "../global/form/validator";

const templateSource = getTemplate("form");

let env;
formValidator.getEnvironment().then((environment) => {
  env = environment;
});

export default BaseView.extend({
  schemaId: "customer",
  events: {
    "submit form": "submit",
  },

  template: Handlebars.compile(templateSource),

  render: function () {
    this.$el.html(
      this.template({
        title: "Uw gegevens",
        class: "customer",
        action: "/profiel?localAction=update",
        data: JSON.stringify(this.model.toJSON()),
      })
    );
    const view = new CustomerFormFields({
      el: this.$(".fieldset")[0],
      model: this.model,
      path: [],
    });
    view.render();

    return this;
  },

  submit: function () {
    if (!env) {
      return false;
    }
    const self = this;
    const customerData = this.model.toJSON();
    const errors = env.validate("customer", customerData, formValidator.jjvOptions);
    if (errors && errors.validation) {
      self.$el.find("input[name], textarea[name], select[name]").each(function () {
        formValidator.toggleErrorClass($(this).prop("name"), errors.validation);
      });
      formValidator.scrollToFirstError();
      return false;
    }
    self.$(".data").val(JSON.stringify(customerData));
    return true;
  },
});
