import $ from "jquery";
import * as utils from "../utils";

export interface IAvailableCard {
  id: number;
  title: string;
  type: number;
  imageId: number;
  price: number;
}

interface IAvailableTimeFrame {
  id: number;
  title: string;
  price: number;
}

interface IAvailableChocolateBar {
  id: number;
  title: string;
  price: number;
  imageUrl: string;
}

interface IItemContext {
  id: number;
  description: string;
  pricePerProduct: number;
  taxMultiplier: number;
  imageFileId: number;
  webProductId: number;
  isPublished: boolean;
  minimumQuantity: number;
  vaseProductCollectionMemberId: number;
  total: number;
}

interface IConstants {
  PRODUCT_ID_INVOICE: number;
  PRODUCT_ID_DISCOUNT: number;
  PRODUCT_ID_CARD: number;
  TIMEFRAME_ID_ALLDAY: number;
  TIMEFRAME_ID_MORNING: number;
  TIMEFRAME_ID_AFTERNOON: number;
  TIMEFRAME_ID_EVENING: number;
  TIMEFRAME_ID_COMPANY: number;
  CATEGORY_ID_OUTSIDENL: number;
  CATEGORY_ID_OUTSIDENL_2014: number;
  CARD_TEXT_MAX_LENGTH: number;
  RIBBON_TEXT_MAX_LENGTH: number;
  CARDTYPE_FUNERAL_ID: number;
  CARDTYPE_NORMAL_ID: number;
  CARDTYPE_FREE_ID: number;
}

export interface ICartContext {
  availableCards: IAvailableCard[];
  availableChocolateBar: IAvailableChocolateBar;
  availableDates: string[];
  availableDeliveryCountries: string[];
  availableExtraServicePhoto: boolean;
  availableTimeFrames: IAvailableTimeFrame[];
  cardLabel: string;
  cardPrice: number;
  constants: IConstants;
  discountError?: string;
  discountValue?: number;
  isAlcohol: boolean;
  isLuxuryCard: boolean;
  isMourning: boolean;
  isPartnerSite: boolean;
  isPhilipsCustomer?: boolean;
  itemContexts: IItemContext[];
  philipsCardTexts?: { [language: string]: string };
  postalInvoicePrice: number;
  quantityCount: number;
  shippingCosts: number;
  timeFrameLabel: string;
  timeFramePrice: number;
  total: number;
  totalCount: number;
  validCustomerZipcode: boolean;
  validRecipientZipcode: boolean;
}

export default function getContextByCart(cart: any): JQueryPromise<ICartContext> {
  const contextInfluencingCartStuff: any = {};
  $.each(
    [
      ["card", "type"],
      ["card", "quantity"],
      ["card", "chocolateBarQuantity"],
      ["address", "recipient", "addressType"],
      ["address", "recipient", "countryCode"],
      ["address", "recipient", "zipcode"],
      ["address", "recipient", "streetNumber"],
      ["address", "customer", "countryCode"],
      ["address", "customer", "zipcode"],
      ["items"],
      ["delivery", "ownDelivery"],
      ["delivery", "date"],
      ["delivery", "timeFrame"],
      ["delivery", "discountCode"],
      ["extraService", "photo"],
      ["final", "postalInvoice"],
    ],
    (key, path) => {
      let nibbleValue = cart;
      let contextInfluencingCartStuffNode = contextInfluencingCartStuff;
      // extract value
      $.each(path, (pathKey: number, nibble: string) => {
        nibbleValue = nibbleValue.get(nibble);

        if (path.length - 1 === pathKey) {
          contextInfluencingCartStuffNode[nibble] = nibbleValue;
          return;
        }

        if (contextInfluencingCartStuffNode[nibble] === undefined) {
          contextInfluencingCartStuffNode[nibble] = {};
        }

        contextInfluencingCartStuffNode = contextInfluencingCartStuffNode[nibble];
      });
      if (contextInfluencingCartStuff.items && contextInfluencingCartStuff.items.toJSON) {
        contextInfluencingCartStuff.items = contextInfluencingCartStuff.items.toJSON();
      }
    }
  );

  return utils
    .getCachedXhrPromise("post", "/gateway/boeketcadeau2014/cart?localAction=getContext", {
      cart: JSON.stringify(contextInfluencingCartStuff),
    })
    .fail((jqXHR: Error) => {
      // tslint:disable-next-line:no-console
      if (console.error) {
        // tslint:disable-next-line:no-console
        console.error(jqXHR, "trying again");
      }

      /* eslint-disable max-len */
      // IE 10 / IE 11 http stack keepalive race condition
      // https://stackoverflow.com/questions/16312938/ie10-ie11-abort-post-ajax-request-after-clearing-cache-with-error-network-error/16818527#16818527
      // http://www.telerik.com/blogs/help!-running-fiddler-fixes-my-app-
      // http://stackoverflow.com/questions/23145688/ie10-11-ajax-xhr-error-script7002-xmlhttprequest-network-error-0x2ef3
      // http://stackoverflow.com/questions/14527387/script7002-xmlhttprequest-network-error-0x2ef3-could-not-complete-the-operati
      // http://hustoknow.blogspot.de/2012/12/microsoft-internet-explorer-12152-errors.html
      // http://jonnyreeves.co.uk/2013/making-xhr-request-to-https-domains-with-winjs/
      // https://stackoverflow.com/questions/16312938/ie10-ie11-abort-post-ajax-request-after-clearing-cache-with-error-network-error/16818527#16818527
      // https://connect.microsoft.com/IE/feedback/details/817359/xmlhttprequest-issue
      /* eslint-enable max-len */

      return $.get("/img/empty.gif").then(() =>
        utils.getCachedXhrPromise(
          "post",
          "/gateway/boeketcadeau2014/cart?localAction=getContext&retry=1",
          { cart: JSON.stringify(contextInfluencingCartStuff) },
          { force: true }
        )
      );
    })
    .then((result: ICartContext) => {
      // / KMT-41666 - Bloemen bestellen voor het buitenland
      if (
        result.availableDeliveryCountries &&
        result.availableDeliveryCountries.indexOf(cart.get("address").get("recipient").get("countryCode")) === -1
      ) {
        cart.get("address").get("recipient").set("countryCode", result.availableDeliveryCountries[0]);
      }
      return result;
    });
}
