import Backbone from "backbone";
import $ from "jquery";
import * as catalogPage from "../pages/catalog";
import * as profilePage from "../pages/profile";
import { TCartView } from "../view/Cart";
import globalCart from "./cart";
import vent from "./vent";

const appRouter = new Backbone.Router({
  routes: {
    addItem: "addItem",
    addressBook: "addressBook",
    "addressBook/edit/:id": "addressBookEdit",
    "addressBook/add": "addressBookAdd",
    "cart/:step": "cartStep",
    history: "history",
    password: "password",
    profile: "profile",
    logout: "logout",
    "*path": "notFound", // Backbone will try match the route above first
  },
});

const toggleMenuPart = (formBarId: string, selected: boolean) => {
  const $bar: any = $(`.${formBarId}`);
  $bar[`slide${selected ? "Down" : "Up"}`]();
  $(`[href="#${formBarId}"]`).parent("li").toggleClass("selected", selected);
};

let hasMenuRegisterModule = false;
// top menu stuff
appRouter.on("route", () => {
  $.each(["loginBar", "registerBar"], (key, formBarId) => {
    const selected = Backbone.history.getHash() === formBarId;
    toggleMenuPart(formBarId, selected);
    if (formBarId === "registerBar" && selected && !hasMenuRegisterModule) {
      hasMenuRegisterModule = true;
      import("./menu/register").then((module) => {
        module.default.init();
      });
    }
  });
});

// profile page stuff
appRouter.on("route:addressBook", profilePage.routeAddressBook);
appRouter.on("route:addressBookEdit", profilePage.routeAddressBookEdit);
appRouter.on("route:addressBookAdd", profilePage.routeAddressBookAdd);
appRouter.on("route:history", profilePage.routeHistory);
appRouter.on("route:password", profilePage.routePassword);
appRouter.on("route:profile", profilePage.routeProfile);
appRouter.on("route:cartStep", (stepId) => {
  // Use the global CartView render event
  vent.on("CartView:render", (cartView: TCartView) => {
    cartView.openStep(parseInt(stepId, 10));
  });
});

appRouter.on("route:logout", () => {
  globalCart.clear().then(() => {
    window.location.href = "/logout";
  });
});

// A non-found route
appRouter.on("route:notFound", () => {
  // open the profile sub-page if on the profile page (e.g. route is "" when opening /profiel)
  profilePage.routeProfile();
  catalogPage.routeDetails();
});

// ALL routes!
appRouter.on("route", (routeName) => {
  profilePage.updateMenu(routeName);
});

// do nothing when addItem is initiated
appRouter.on("route:addItem", $.noop);
appRouter.on("route:webProductDetails", catalogPage.routeDetails);
