import $ from "jquery";

import "./index.scss";

const CoverModal = () => {
  if (!window.coverModal || !window.coverModal.id || !window.coverModal.content) {
    return;
  }

  const { id, blockCart, versionId, title, content } = window.coverModal;

  const COOKIE_NAME = `hasReadCover${id}`;

  const cookieRE = new RegExp(`(?:(?:^|.*;\\s*)${COOKIE_NAME}\\s*\\=\\s*([^;]*).*$)|^.*$`);

  const acceptedVersionId = parseInt(document.cookie.replace(cookieRE, "$1"));

  if (acceptedVersionId === versionId) {
    // already viewed this version
    return;
  }

  const prefix = "";
  // if (acceptedVersionId < versionId) {
  //   prefix = '<div class="alert alert-info">Dit informatie is inmiddels bijgewerkt</div>';
  // }

  $("<div />")
    .html(`<div>${prefix + content}</div>`)
    .dialog({
      autoOpen: true,
      resizable: false, // this is due to the theme not being loaded properly so we have missing styling
      title,
      width: $("footer").width(),
      modal: true,
      buttons: blockCart ? [] : [
        {
          text: "Beginnen met winkelen",
          click: function () {
            $(this).dialog("close");
          },
        },
      ],
      close: function () {
        document.cookie = `${COOKIE_NAME}=${versionId}; expires=Fri, 31 Dec ${new Date().getUTCFullYear()} 23:59:59 GMT`;
      },
      open: function () {
        $(this)
          .parent()
          .addClass("CoverModal")
          .find(".ui-dialog-buttonset button")
          .removeClass()
          .blur()
          .addClass("CoverModal__Button");
      },
    });
};

export default CoverModal;
