import $ from "jquery";
import "jquery-ui/ui/effect";
import "jquery-ui/ui/effects/effect-transfer";
import "fancybox/dist/js/jquery.fancybox.cjs";
import Handlebars, { getTemplate } from "global/renderEngine";
import { isMobileVisit } from "global/utils";
import globalSchema from "global/schema";
import globalCart from "global/cart";

const template = getTemplate("card/dialog");

const tpl = Handlebars.compile(template);

let $contents = false;

export const updateCounter = (e) => {
  const $cardTextInput = $(e.target);
  const $counter = $cardTextInput.parent().find(".counterContainer span");

  const currentValue = ($cardTextInput.val() || "").replace(/\r?\n/g, "\n"); // eslint-disable-line quotes
  let stringLength = currentValue.length;
  const maxLength = $cardTextInput.data("maxlength") || 300;

  if (stringLength > maxLength) {
    $cardTextInput.val(currentValue.substring(0, maxLength));
    $cardTextInput.change();
    stringLength = maxLength;
  }
  $counter.html(maxLength - stringLength);
};

export const clickHandler = (e) => {
  e.preventDefault();

  const $el = $(e.currentTarget);

  const $container = $el.hasClass("edit-button") ? $el.parent() : $el;

  const title = $container.find("div").text();

  const cardId = parseInt($container.data("id"), 10);

  const imageId = parseInt(
    $container
      .find("img")
      .attr("src")
      .replace(/.*id=([0-9]+).*/, "$1"),
    10
  );

  if (!$contents) {
    $contents = $("<div/>").css("display", "none").appendTo($("body"));
  }

  $.when(globalSchema.getCartPromise(), globalCart.get()).then((cartSchema, cart) => {
    //corky deep clone
    const cardSchema = JSON.parse(JSON.stringify(cartSchema.properties.card));

    $contents.html(
      tpl({
        id: cardId,
        imageId: imageId,
        maxLength: cardSchema.properties.text.maxLength,
      })
    );

    const isMobile = isMobileVisit();
    const width = isMobile ? 320 : 482;
    const height = isMobile ? 530 : 620;

    const padding = 36;

    $.fancybox({
      wrapCSS: "fancy-yo",
      type: "html",
      width: width - padding * 2 - 20 * 2,
      minWidth: width - padding * 2 - 20 * 2,
      maxWidth: width,
      height: height, // just a hint
      minHeight: height, // just a hint
      autoWidth: false,
      autoHeight: false,
      autoResize: false,
      autoSize: false,
      padding: padding,
      margin: [50, 20, 20, 20], //extra margin for title
      closeBtn: false,
      tpl: {
        wrap:
          '<div class="fancybox-wrap fancybox-card" tabIndex="-1">' +
          '<a href="#" class="closeButton">Sluiten</a>' +
          '<div class="fancybox-skin"><div class="fancybox-outer">' +
          '<div class="fancybox-inner"></div></div></div></div>',
      },
      title: title,
      content: $contents.html(),
      afterLoad: function () {
        window.setTimeout(() => {
          const $wrap = $(this.wrap);
          const $titleContainer = $wrap.find(".fancybox-title");
          $titleContainer.css({
            width: "auto",
            top: `${-$titleContainer.outerHeight()}px`,
          });
        }, 100);
        const $wrap = $(this.wrap);
        $wrap
          .on("click", ".closeButton", () => {
            //event
            if ($.fancybox && $.fancybox.close) {
              $.fancybox.close();
            }
            return false;
          })
          .on("mouseleave", ".fancybox-skin", () => {
            $.fancybox.close();
          })
          .on("change", "textarea", updateCounter)
          .on("keyup", "textarea", updateCounter)
          .on("paste", "textarea", updateCounter)
          .on("click", 'input[type="submit"]', (e) => {
            e.preventDefault();

            const $input = $(e.currentTarget);

            cart.get("card").set("type", cardId, { silent: true });
            cart.get("card").set("text", $input.parent().parent().find("textarea").val());

            cart.get("delivery").set({ ownDelivery: true });

            $wrap.find(".cart-card img").css("z-index", 10000).effect("transfer", { to: ".menuCart" }, 1200);

            $(".ui-effects-transfer").css({
              background: `#FFF url(${$wrap.find("img").prop("src")}) no-repeat center top`,
              "-webkit-background-size": "contain",
              "-moz-background-size": "contain",
              "-o-background-size": "contain",
              "background-size": "contain",
            });

            document.location = "/";
            $.fancybox.close();
          });
      },
    });
  });
};

const $luxuryCardTypeBar = $(".luxuryCardTypeBar");
const $cards = $luxuryCardTypeBar.find("li");
const $buttons = $luxuryCardTypeBar.find(".edit-button");

if ($cards.length > 0) {
  $cards.on("click", clickHandler);
}
if (!isMobileVisit() && $buttons.length > 0) {
  $buttons.on("mouseover", clickHandler);
}
